<template>
  <div class="loading-skeleton">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-body">
            <div class="p-0">
              <label>headerheaderheaderheaasdssd</label>
            </div>
            <div class="pt-1">
              <label>headerheaderheaderhea</label>
            </div>

            <!-- <div class="pt-4">
              <label>headerheaderheaderheaasdsas</label>
              <label class="ms-3">headerheadas</label>
            </div>
            <div class="pt-1">
              <label>headerheaderheaderhea</label>
            </div> -->

            <div class="row d-flex justify-content-center mb-4">
              <div class="col-sm-12">
                <h5 style="height: 492px" class="card-title mt-10">
                  Card title
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../../assets/sass/loader.scss";
</style>
