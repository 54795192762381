<template>
  <div :class="widgetClasses" class="card">
    <div class="card-body pb-0 px-0" v-if="!isShow" style="height: 443px"></div>
    <div class="card-body pb-0 px-0" v-if="isShow">
      <div class="d-flex flex-stack px-9">
        <div class="d-flex flex-column">
          <h2 class="text-dark mb-1 fs-2 fw-boldest">
            ยอดขายตามหมวดหมู่รายเดือน
          </h2>
          <span class="text-gray-600 fw-bold mt-2 fs-6"
            >เรียงลำดับยอดขายตามหมวดหมู่สินค้า</span
          >
        </div>

        <div class="d-flex align-items-center">
          <a
            class="btn btn-sm btn-icon btn-circle btn-icon btn-active-light"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/general/gen023.svg" />
            </span>
          </a>
          <!-- <Dropdown1></Dropdown1> -->
        </div>
      </div>

      <div class="tab-content pt-8">
        <div
          class="tab-pane fade active show"
          id="kt_charts_widget_3_tab_pane_1"
        >
          <apexchart
            type="bar"
            height="320"
            :options="chartOptions"
            :series="series1"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

import { dataReport1 } from "@/core/report/report1/dataReport1";
import { filterTimeByHour } from "@/utils/dashboard/chart1/filterReport1";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-2",
  props: {
    widgetClasses: String,
    categories: Array,
    searchSalesThisMonth: Array,
    searchSalesThisMonthLastYear: Array,
  },
  components: { Dropdown1 },
  setup() {
    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["40%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["transparent"],
      },
      xaxis: {
        // crosshairs: {
        //   show: false,
        // },
        // categories: [
        //   "Med",
        //   "Cosmetics",
        //   "Vitamins",
        //   "Skincares",
        //   "Mom",
        //   "Baths",
        //   "Equipment",
        // ],

        categories: [],
        // axisBorder: {
        //   show: false,
        // },
        // axisTicks: {
        //   show: false,
        // },
        labels: {
          style: {
            colors: "black",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        // crosshairs: {
        //   show: false,
        // },
        labels: {
          style: {
            colors: getCSSVariableValue("--bs-gray-400"),
            fontSize: "12px",
          },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ["#7337EE", "#DCDCDC"],
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return numbro(val).format({
              thousandSeparated: true,
            });
          },
        },
      },
      colors: [getCSSVariableValue("--bs-primary")],
      grid: {
        borderColor: getCSSVariableValue("--bs-gray-300"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    // const series1 = [
    //   {
    //     name: "Profit",
    //     data: [30, 40, 30, 25, 40, 45, 30],
    //   },
    // ];

    const series1 = [
      {
        name: "มูลค่าปีนี้",
        // data: [30, 40, 30, 25, 40, 45, 30, 40, 45, 30],
        data: [],
      },
      {
        name: "มูลค่าปีก่อน",
        // data: [35, 30, 40, 20, 50, 55, 25, 50, 55, 25],
        data: [],
      },
    ];

    return {
      chartOptions,
      series1,
    };
  },

  data: () => ({
    isShow: false,
    isEmpty: 0,

    reportDate: [],
    reportDateLastYear: [],

    total: 0,
    totalLastYear: 0,

    compareValue: 0,
  }),

  async created() {
    await this.search();
    await this.searchLastYear();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      this.handleFilterReport(this.searchSalesThisMonth);
    },

    async searchLastYear() {
      this.handleFilterReport1(this.searchSalesThisMonthLastYear);
    },

    async handleFilterReport(item) {
      if (item.length) {
        let newItemSales = [];
        let newItemPos = [];
        let groupItem = [];

        // set hour sales order
        item[0].forEach((element, index) => {
          newItemSales.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        // set hour POS
        item[1].forEach((element, index) => {
          newItemPos.push({
            ...element,
            salesOrderItems: element.pointOfSaleItems,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        groupItem = [...newItemSales, ...newItemPos];

        this.filterByCate(groupItem);
      }
    },

    filterByCate(groupItem) {
      let newCate = this.categories.map(
        ({
          activeFlag,
          amount,
          companyId,
          createdAt,
          id,
          main_product_group_code,
          main_product_group_name,
          status,
          updatedAt,
        }) => ({
          activeFlag: activeFlag,
          amount: amount,
          companyId: companyId,
          createdAt: createdAt,
          id: id,
          main_product_group_code: main_product_group_code,
          main_product_group_name: main_product_group_name,
          status: status,
          updatedAt: updatedAt,
        })
      );

      // console.log(this.categories, 111);
      // console.log(groupItem, 123);
      // console.log(newCate, 321);

      this.categories.forEach((element, index) => {
        const mainPdId = element.id;

        // this.chartOptions.xaxis.categories.push(
        //   // element.main_product_group_name
        //   element.main_product_group_name.length > 10
        //     ? element.main_product_group_name.substring(0, 10) + "..."
        //     : element.main_product_group_name
        // );

        groupItem.forEach((element2) => {
          element2.salesOrderItems.forEach((element3) => {
            if (element3.product.mainProductGroupId === mainPdId) {
              // console.log(element3.product.mainProductGroupId, 123);
              if (element2.sales_type && element2.sales_type === "0") {
                const total =
                  parseFloat(element3.product.product_wholesale_price) *
                  parseFloat(element3.item_amt);

                newCate[index] = {
                  ...newCate[index],
                  total: newCate[index].total
                    ? newCate[index].total + total
                    : 0 + total,
                };

                // console.log(newCate[index]);
              } else {
                const total = element3.product.product_promotion_price
                  ? parseFloat(element3.product.product_promotion_price) *
                    parseFloat(element3.item_amt)
                  : parseFloat(element3.product.product_retail_price) *
                    parseFloat(element3.item_amt);

                newCate[index] = {
                  ...newCate[index],
                  total: newCate[index].total
                    ? newCate[index].total + total
                    : 0 + total,
                };
              }
            }
          });
        });
      });

      // console.log(newCate);

      this.reportDate = newCate;

      this.setSeries(newCate);
    },

    setSeries(newCate) {
      this.series1[0].data = newCate.map((data) =>
        data.total ? data.total : 0
      );

      this.series1[0].data.sort((a, b) => b - a);

      if (this.series1[0].data.length > 10) {
        this.series1[0].data.splice(10, this.series1[0].data.length);
      }

      const sortArr = newCate.sort((a, b) => b.total - a.total);
      sortArr.forEach((element) => {
        this.chartOptions.xaxis.categories.push(
          // element.main_product_group_name
          element.main_product_group_name.length > 10
            ? element.main_product_group_name.substring(0, 10) + "..."
            : element.main_product_group_name
        );
      });
    },

    // last year
    async handleFilterReport1(item) {
      if (item.length) {
        let newItemSales = [];
        let newItemPos = [];
        let groupItem = [];

        // set hour sales order
        item[0].forEach((element, index) => {
          newItemSales.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        // set hour POS
        item[1].forEach((element, index) => {
          newItemPos.push({
            ...element,
            salesOrderItems: element.pointOfSaleItems,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        groupItem = [...newItemSales, ...newItemPos];

        this.filterByCate1(groupItem);
      }
    },

    filterByCate1(groupItem) {
      let newCate = this.categories.map(
        ({
          activeFlag,
          amount,
          companyId,
          createdAt,
          id,
          main_product_group_code,
          main_product_group_name,
          status,
          updatedAt,
        }) => ({
          activeFlag: activeFlag,
          amount: amount,
          companyId: companyId,
          createdAt: createdAt,
          id: id,
          main_product_group_code: main_product_group_code,
          main_product_group_name: main_product_group_name,
          status: status,
          updatedAt: updatedAt,
        })
      );

      this.categories.forEach((element, index) => {
        const mainPdId = element.id;

        groupItem.forEach((element2) => {
          element2.salesOrderItems.forEach((element3) => {
            if (element3.product.mainProductGroupId === mainPdId) {
              if (element2.sales_type && element2.sales_type === "0") {
                const total =
                  parseFloat(element3.product.product_wholesale_price) *
                  parseFloat(element3.item_amt);

                newCate[index] = {
                  ...newCate[index],
                  total: newCate[index].total
                    ? newCate[index].total + total
                    : 0 + total,
                };
              } else {
                const total = element3.product.product_promotion_price
                  ? parseFloat(element3.product.product_promotion_price) *
                    parseFloat(element3.item_amt)
                  : parseFloat(element3.product.product_retail_price) *
                    parseFloat(element3.item_amt);

                newCate[index] = {
                  ...newCate[index],
                  total: newCate[index].total
                    ? newCate[index].total + total
                    : 0 + total,
                };
              }
            }
          });
        });
      });

      this.reportDate = newCate;

      this.setSeries1(newCate);
    },

    setSeries1(newCate) {
      this.series1[1].data = newCate.map((data) =>
        data.total ? data.total : 0
      );

      this.series1[1].data.sort((a, b) => b - a);

      if (this.series1[1].data.length > 10) {
        this.series1[1].data.splice(10, this.series1[1].data.length);
      }

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },
  },
});
</script>
