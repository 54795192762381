<template>
  <div :class="widgetClasses" class="card">
    <div class="card-body pb-0 px-0">
      <div class="d-flex flex-stack px-9">
        <div class="d-flex flex-column">
          <h2 class="text-dark mb-1 fs-2 fw-boldest">
            ความเคลื่อนไหวคลังสินค้า
          </h2>
          <span class="text-gray-600 fw-bold mt-2 fs-6"
            >ข้อมูลคลังสินค้าปัจจุบัน This Year</span
          >
        </div>

        <div class="d-flex align-items-center">
          <a
            class="btn btn-sm btn-icon btn-circle btn-icon btn-active-light"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/general/gen023.svg" />
            </span>
          </a>
          <!-- <Dropdown1></Dropdown1> -->
        </div>
      </div>

      <div class="d-flex flex-stack px-9 mt-5">
        <div class="row">
          <div class="col-12">
            <span style="color: grey; font-size: 28px">฿ </span>
            <span style="font-size: 30px"> {{ formatThousand(total) }} </span>
            <button
              style="padding: 5px; margin-bottom: 10px; margin-left: 3px"
              :class="
                compareValue === 0
                  ? 'btn btn-sm btn-light-primary fw-boldest'
                  : compareValue > 0
                  ? 'btn btn-sm btn-light-success fw-boldest'
                  : 'btn btn-sm btn-light-danger fw-boldest'
              "
              data-toggle="tooltip"
              :title="`Last year : ${formatThousand(totalLastYear)}`"
            >
              <i
                :class="
                  compareValue === 0
                    ? 'bi bi-arrow-right'
                    : compareValue > 0
                    ? 'bi bi-arrow-up'
                    : 'bi bi-arrow-down'
                "
              ></i
              >{{ compareValue.toFixed(1) }} %
            </button>
          </div>
          <div class="col-12">
            <div class="col fs-5" style="color: grey">
              Total Stock This Year
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content pt-8">
        <div
          class="tab-pane fade active show"
          id="kt_charts_widget_3_tab_pane_1"
        >
          <apexchart
            class="card-rounded-bottom h-125px"
            type="area"
            height="230"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    snapshotTen: Array,
    snapshotTenLastYear: Array,
    inputDate: Array,
    snapshotTenForSum: Array,
    snapshotTenLastYearForSum: Array,
  },
  components: {},
  setup() {
    const chartOptions = {
      fill: {
        colors: ["#8950FC"],
      },
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        colors: ["#8950FC"],
      },
      xaxis: {
        categories: [],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    };

    const series = [
      {
        name: "มูลค่า",
        data: [],
      },
    ];

    return {
      chartOptions,
      series,
    };
  },

  data() {
    return {
      total: 0,
      totalLastYear: 0,

      compareValue: 0,
    };
  },

  async created() {
    this.oldDataSnapshotTen = this.snapshotTen;
    await this.search();
  },

  methods: {
    formatThousand(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
        });
        return newVal;
      }
    },
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      this.handleFilterReport(this.snapshotTen);
    },

    async handleFilterReport() {
      const newDateStart = dayjs(this.inputDate[0].$d);
      const newDateEnd = dayjs(this.inputDate[0].$d)
        .endOf("month")
        .subtract(12, "month");

      const newFormatStart = dayjs(newDateStart.$d).format("YYYY-MM-DD");
      const newFormatEnd = dayjs(newDateEnd.$d).format("YYYY-MM-DD");

      let countMonth = dayjs(newFormatStart).diff(dayjs(newFormatEnd), "month");

      countMonth < 0 ? (countMonth *= -1) : countMonth;

      this.setXChart(newDateStart, newDateEnd, countMonth);
    },

    setXChart(newDateStart, newDateEnd, countMonth) {
      let newArrDate = [];

      for (let index = 0; index <= countMonth - 2; index++) {
        newArrDate.push(
          dayjs(newDateStart)
            .subtract(index, "month")
            .format("YYYY-MMMM")
            .substring(5, 8)
        );
      }

      this.setSeries();

      newArrDate = newArrDate.reverse();

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: newArrDate,
          convertedCatToNumeric: false,
        },
      };
    },

    setSeries() {
      let newItem = this.snapshotTen;
      newItem.length = 10;

      newItem.forEach((element) => {
        if (element.warehouseSnapshotItems) {
          const sumQty = element.warehouseSnapshotItems.reduce(
            (sum, data) =>
              sum + data.product_bring_qty * data.product_retail_price,
            0
          );
          this.series[0].data.push(sumQty);
        } else {
          this.series[0].data.push(0);
        }
      });

      this.series[0].data.reverse();

      this.sumTotal();
    },

    sumTotal() {
      let newArr1 = [];

      this.snapshotTenForSum.forEach((element) => {
        if (element.warehouseSnapshotItems) {
          const sumQty = element.warehouseSnapshotItems.reduce(
            (sum, data) =>
              sum + data.product_bring_qty * data.product_retail_price,
            0
          );

          newArr1.push(sumQty);
        } else {
          newArr1.push(0);
        }
      });

      this.total = newArr1.reduce((sum, data) => sum + data, 0);

      let newArr = [];

      this.snapshotTenLastYearForSum.forEach((element) => {
        if (element.warehouseSnapshotItems) {
          const sumQty = element.warehouseSnapshotItems.reduce(
            (sum, data) =>
              sum + data.product_bring_qty * data.product_retail_price,
            0
          );
          newArr.push(sumQty);
        } else {
          newArr.push(0);
        }
      });

      this.totalLastYear = newArr.reduce((sum, data) => sum + data, 0);

      this.compereLastYear();
    },

    compereLastYear() {
      if (this.totalLastYear === 0) {
        this.compareValue = 100;
      } else {
        this.compareValue =
          ((parseFloat(this.total) - parseFloat(this.totalLastYear)) /
            parseFloat(this.totalLastYear)) *
          100;
      }

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },

    close() {
      this.$emit("closeDialogChart3");
    },
  },
});
</script>
