<template>
  <div
    :class="widgetClasses"
    class="card"
    style="height: 700px; max-height: 700px"
  >
    <div class="card-body pb-0 px-0" v-if="!isShow"></div>

    <div class="card-header border-0 pt-5 pb-3" v-if="isShow">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-boldest text-gray-800 fs-2">
          Transaction Summary</span
        >
        <span class="text-gray-600 fw-bold mt-2 fs-6"
          >{{ formatPrice(searchTrans.length) }} รายการวันนี้</span
        >
      </h3>
      <div class="d-flex align-items-center">
        <a
          class="btn btn-sm btn-icon btn-circle btn-icon btn-active-light"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
        >
          <span class="svg-icon svg-icon-1">
            <inline-svg src="media/icons/duotune/general/gen023.svg" />
          </span>
        </a>
      </div>
    </div>

    <div class="card-body py-0" v-if="isShow">
      <div class="table-responsive">
        <table
          class="table align-middle table-row-bordered table-row-dashed gy-5"
          id="kt_table_widget_1"
        >
          <tbody>
            <tr class="text-start text-gray-400 fw-boldest fs-7 text-uppercase">
              <th class="min-w-200px pt-0 px-0"></th>
              <th class="min-w-100px pt-0"></th>
              <th class="min-w-100px pt-0"></th>
            </tr>

            <template v-for="(item, i) in items" :key="i">
              <tr>
                <td class="py-0 px-0">
                  <div class="d-flex align-items-center">
                    <div class="symbol symbol-circle symbol-30px me-2">
                      <span class="symbol-label">
                        <img alt="" class="w-15px" :src="item.logo" />
                      </span>
                    </div>

                    <div class="ps-3">
                      <a
                        class="text-gray-800 fw-boldest fs-5 text-hover-primary mb-1"
                        >{{ item.name }}</a
                      >
                      <span class="text-gray-400 fw-bold d-block">{{
                        item.tools
                      }}</span>
                    </div>
                  </div>
                </td>
                <td class="px-0">
                  <span class="text-gray-800 fw-boldest fs-5 d-block text-end">
                    {{ formatPrice(item.total) }}
                  </span>
                </td>

                <td>
                  <div class="d-flex justify-content-center">
                    <button style="padding: 5px" :class="item.style">
                      <i :class="item.icon"></i
                      >{{
                        i === 0
                          ? compareValue1.toFixed(1)
                          : i === 1
                          ? compareValue2.toFixed(1)
                          : i === 2
                          ? compareValue3.toFixed(1)
                          : i === 3
                          ? compareValue4.toFixed(1)
                          : i === 4
                          ? compareValue5.toFixed(1)
                          : compareValue6.toFixed(1)
                      }}
                      %
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    searchTrans: Array,
    searchTransLastYear: Array,
  },
  components: {},
  setup() {
    // const all = ref < boolean > false;
    const items = [
      {
        logo: "media/icons/duotune/coding/cod002.svg",
        name: "รายการเพิ่มสินค้าใหม่",
        tools: "Add New Product",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/misc/puzzle.svg",
        name: "รายการขาย POS",
        tools: "POS Sales",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/brand-logos/aven.svg",
        name: "รายการขายปลีก",
        tools: "Retail Sales",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/brand-logos/leaf.svg",
        name: "รายการขายส่ง",
        tools: "Whole Sales",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/brand-logos/atica.svg",
        name: "รายการซื้อ",
        tools: "Purchases",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/brand-logos/balloon.svg",
        name: "โอนสินค้าเข้า(+)",
        tools: "Transfer in(+)",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/brand-logos/balloon.svg",
        name: "โอนสินค้าออก(-)",
        tools: "Transfer out(-)",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/misc/infography.svg",
        name: "ปรับสินค้าเข้าคลัง(+)",
        tools: "Add in(+)",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/misc/infography.svg",
        name: "ปรับสินค้าออกคลัง(-)",
        tools: "Adjust out(-)",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
    ];

    const items2 = [
      {
        logo: "media/svg/misc/puzzle.svg",
        name: "รายการเพิ่มสินค้าใหม่",
        tools: "Retail Sales",
        total: 0,
        icon: "bi bi-arrow-up",
        style: "btn btn-sm btn-light-success fw-boldest",
      },
      {
        logo: "media/svg/misc/puzzle.svg",
        name: "รายการขาย POS",
        tools: "Retail Sales",
        total: 0,
      },
      {
        logo: "media/svg/brand-logos/aven.svg",
        name: "รายการขายปลีก",
        tools: "Retail Sales",
        total: 0,
      },
      {
        logo: "media/svg/brand-logos/leaf.svg",
        name: "รายการขายส่ง",
        tools: "Whole Sales",
        total: 0,
      },
      {
        logo: "media/svg/brand-logos/atica.svg",
        name: "รายการซื้อ",
        tools: "Purchases",
        total: 0,
      },
      {
        logo: "media/svg/brand-logos/balloon.svg",
        name: "รายการโอนสินค้าเข้า(+)",
        tools: "Transfer in/out",
        total: 0,
      },
      {
        logo: "media/svg/brand-logos/balloon.svg",
        name: "รายการโอนสินค้าออก(-)",
        tools: "Transfer in/out",
        total: 0,
      },
      {
        logo: "media/svg/misc/infography.svg",
        name: "รายการปรับสินค้าเข้าคลัง(+)",
        tools: "Stock Adjustments",
        total: 0,
      },
      {
        logo: "media/svg/misc/infography.svg",
        name: "รายการปรับสินค้าออกคลัง(-)",
        tools: "Stock Adjustments",
        total: 0,
      },
    ];

    return {
      items,
      items2,
      // all,
    };
  },

  data: () => ({
    isShow: false,
    isEmpty: 0,

    reportDate: [],
    reportDateLastYear: [],

    total: 0,
    totalLastYear: 0,

    compareValue1: 0,
    compareValue2: 0,
    compareValue3: 0,
    compareValue4: 0,
    compareValue5: 0,
    compareValue6: 0,
  }),

  async created() {
    await this.search();
    await this.searchLastYear();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      this.handleFilterReport(this.searchTrans);
    },

    async searchLastYear() {
      this.handleFilterReport1(this.searchTransLastYear);
    },

    async handleFilterReport(item) {
      if (item.length) {
        let newItem = [];
        item.forEach((element) => {
          newItem.push({
            ...element,
            title_type: element.product_new_code
              ? element.product_new_code.substring(0, 2)
              : element.sales_no
              ? element.sales_no.substring(0, 2)
              : element.point_of_sale_no
              ? element.point_of_sale_no.substring(0, 2)
              : element.transfer_no
              ? element.transfer_no.substring(0, 2)
              : element.cut_off_no
              ? element.cut_off_no.substring(0, 2)
              : element.receive_no
              ? element.receive_no.substring(0, 2)
              : "",
          });
        });

        this.setDataToTable(newItem);
      }
    },

    setDataToTable(newItem) {
      newItem.forEach((element) => {
        // new priduct
        if (element.title_type === "NE") {
          // this.items[0].total =
          //   this.items[0].total + parseFloat(element.final_price);
          this.items[0].total = this.items[0].total + 1;
        }

        // pos
        if (element.title_type === "PO") {
          // this.items[0].total =
          //   this.items[0].total + parseFloat(element.final_price);
          this.items[1].total = this.items[1].total + 1;
        }

        // sales
        if (element.title_type === "80") {
          if (element.sales_type === "1") {
            this.items[2].total = this.items[2].total + 1;
          } else {
            this.items[3].total = this.items[3].total + 1;
          }
        }

        // purchase
        if (element.title_type === "RO") {
          this.items[4].total = this.items[4].total + 1;
        }

        // transfer
        if (element.title_type === "TI") {
          this.items[5].total = this.items[5].total + 1;
        }
        if (element.title_type === "TO") {
          this.items[6].total = this.items[6].total + 1;
        }

        // cutoff
        if (element.title_type === "AD") {
          this.items[7].total = this.items[7].total + 1;
        }
        if (element.title_type === "DE") {
          this.items[8].total = this.items[8].total + 1;
        }
      });
    },

    async handleFilterReport1(item) {
      let newItem = [];
      item.forEach((element) => {
        newItem.push({
          ...element,
          title_type: element.sales_no
            ? element.sales_no.substring(0, 2)
            : element.point_of_sale_no
            ? element.point_of_sale_no.substring(0, 2)
            : element.transfer_no
            ? element.transfer_no.substring(0, 2)
            : element.cut_off_no
            ? element.cut_off_no.substring(0, 2)
            : element.receive_no
            ? element.receive_no.substring(0, 2)
            : "",
        });
      });

      this.setDataToTable1(newItem);
    },

    setDataToTable1(newItem) {
      newItem.forEach((element) => {
        // pos
        if (element.title_type === "PO") {
          // this.items[0].total =
          //   this.items[0].total + parseFloat(element.final_price);
          this.items[0].total = this.items[0].total + 1;
        }

        // sales
        if (element.title_type === "80") {
          if (element.sales_type === "1") {
            this.items[1].total = this.items[1].total + 1;
          } else {
            this.items[2].total = this.items[2].total + 1;
          }
        }

        // purchase
        if (element.title_type === "RO") {
          this.items[3].total = this.items[3].total + 1;
        }

        // transfer
        if (element.title_type === "TO" || element.title_type === "TI") {
          this.items[4].total = this.items[4].total + 1;
        }

        // cutoff
        if (element.title_type === "DE" || element.title_type === "AD") {
          this.items[5].total = this.items[5].total + 1;
        }
      });

      this.compareLastYear();
    },

    compareLastYear() {
      if (this.items2[0].total === 0) {
        this.compareValue1 = 100;
      } else {
        this.compareValue1 =
          ((parseFloat(this.items[0].total) -
            parseFloat(this.items2[0].total)) /
            parseFloat(this.items2[0].total)) *
          100;
      }

      if (this.items2[1].total === 0) {
        this.compareValue2 = 100;
      } else {
        this.compareValue2 =
          ((parseFloat(this.items[1].total) -
            parseFloat(this.items2[1].total)) /
            parseFloat(this.items2[1].total)) *
          100;
      }

      if (this.items2[2].total === 0) {
        this.compareValue3 = 100;
      } else {
        this.compareValue3 =
          ((parseFloat(this.items[2].total) -
            parseFloat(this.items2[2].total)) /
            parseFloat(this.items2[2].total)) *
          100;
      }

      if (this.items2[3].total === 0) {
        this.compareValue4 = 100;
      } else {
        this.compareValue4 =
          ((parseFloat(this.items[3].total) -
            parseFloat(this.items2[3].total)) /
            parseFloat(this.items2[3].total)) *
          100;
      }

      if (this.items2[4].total === 0) {
        this.compareValue5 = 100;
      } else {
        this.compareValue5 =
          ((parseFloat(this.items[4].total) -
            parseFloat(this.items2[4].total)) /
            parseFloat(this.items2[4].total)) *
          100;
      }

      if (this.items2[5].total === 0) {
        this.compareValue6 = 100;
      } else {
        this.compareValue6 =
          ((parseFloat(this.items[5].total) -
            parseFloat(this.items2[5].total)) /
            parseFloat(this.items2[5].total)) *
          100;
      }

      this.fixedIconData();
      this.fixedStyleData();
    },

    fixedIconData() {
      this.compareValue1 < 0
        ? (this.items[0].icon = "bi bi-arrow-down")
        : this.compareValue1 === 0
        ? "bi bi-arrow-right"
        : "bi bi-arrow-up";

      this.compareValue2 < 0
        ? (this.items[1].icon = "bi bi-arrow-down")
        : this.compareValue2 === 0
        ? "bi bi-arrow-right"
        : "bi bi-arrow-up";

      this.compareValue3 < 0
        ? (this.items[2].icon = "bi bi-arrow-down")
        : this.compareValue3 === 0
        ? "bi bi-arrow-right"
        : "bi bi-arrow-up";

      this.compareValue4 < 0
        ? (this.items[3].icon = "bi bi-arrow-down")
        : this.compareValue4 === 0
        ? "bi bi-arrow-right"
        : "bi bi-arrow-up";

      this.compareValue5 < 0
        ? (this.items[4].icon = "bi bi-arrow-down")
        : this.compareValue5 === 0
        ? "bi bi-arrow-right"
        : "bi bi-arrow-up";

      this.compareValue6 < 0
        ? (this.items[5].icon = "bi bi-arrow-down")
        : this.compareValue6 === 0
        ? "bi bi-arrow-right"
        : "bi bi-arrow-up";
    },

    fixedStyleData() {
      this.compareValue1 < 0
        ? (this.items[0].style = "btn btn-sm btn-light-danger fw-boldest")
        : this.compareValue1 === 0
        ? "btn btn-sm btn-light-primary fw-boldest"
        : "btn btn-sm btn-light-success fw-boldest";

      this.compareValue2 < 0
        ? (this.items[1].style = "btn btn-sm btn-light-danger fw-boldest")
        : this.compareValue2 === 0
        ? "btn btn-sm btn-light-primary fw-boldest"
        : "btn btn-sm btn-light-success fw-boldest";

      this.compareValue3 < 0
        ? (this.items[2].style = "btn btn-sm btn-light-danger fw-boldest")
        : this.compareValue3 === 0
        ? "btn btn-sm btn-light-primary fw-boldest"
        : "btn btn-sm btn-light-success fw-boldest";

      this.compareValue4 < 0
        ? (this.items[3].style = "btn btn-sm btn-light-danger fw-boldest")
        : this.compareValue4 === 0
        ? "btn btn-sm btn-light-primary fw-boldest"
        : "btn btn-sm btn-light-success fw-boldest";

      this.compareValue5 < 0
        ? (this.items[4].style = "btn btn-sm btn-light-danger fw-boldest")
        : this.compareValue5 === 0
        ? "btn btn-sm btn-light-primary fw-boldest"
        : "btn btn-sm btn-light-success fw-boldest";

      this.compareValue6 < 0
        ? (this.items[5].style = "btn btn-sm btn-light-danger fw-boldest")
        : this.compareValue6 === 0
        ? "btn btn-sm btn-light-primary fw-boldest"
        : "btn btn-sm btn-light-success fw-boldest";

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },
  },
});
</script>

<style scoped></style>
