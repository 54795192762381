export const dataReport1 = () => {
  const newData = [
    {
      time: "00.00 - 00.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "01.00 - 01.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "02.00 - 02.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "03.00 - 03.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "04.00 - 04.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "05.00 - 05.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "06.00 - 06.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "07.00 - 07.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "08.00 - 08.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "09.00 - 09.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "10.00 - 10.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "11.00 - 11.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "12.00 - 12.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "13.00 - 13.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "14.00 - 14.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "15.00 - 15.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "16.00 - 16.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "17.00 - 17.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "18.00 - 18.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "19.00 - 19.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "20.00 - 20.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "21.00 - 21.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "22.00 - 22.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
    {
      time: "23.00 - 23.59",
      sales: 0,
      customerAmt: 0,
      salesPerBill: 0,
      productAmt: 0,
    },
  ];

  return newData;
};
