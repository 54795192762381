<template>
  <div :class="widgetClasses" class="card">
    <div class="card-body pb-0 px-0" v-if="!isShow" style="height: 510px"></div>
    <div class="card-body pb-0 px-0" v-if="isShow" style="height: 510px">
      <div class="d-flex flex-stack px-9">
        <div class="d-flex flex-column">
          <h2 class="text-dark mb-1 fs-2 fw-boldest">แนวโน้มยอดขาย</h2>
          <span class="text-gray-600 fw-bold mt-2 fs-6"
            >ยอดขายรายเดือน This Year</span
          >
        </div>

        <div class="d-flex align-items-center">
          <a
            class="btn btn-sm btn-icon btn-circle btn-icon btn-active-light"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="media/icons/duotune/general/gen023.svg" />
            </span>
          </a>
          <!-- <Dropdown1></Dropdown1> -->
        </div>
      </div>

      <div class="d-flex flex-stack px-9 mt-5">
        <div class="row">
          <div class="col-12">
            <span style="color: grey; font-size: 28px">฿</span>
            <span style="font-size: 30px">
              {{ " " }}{{ formatPrice(total) }}{{ " " }}
            </span>
            <button
              style="padding: 5px"
              :class="
                compareValue === 0
                  ? 'btn btn-sm btn-light-primary fw-boldest mb-3'
                  : compareValue > 0
                  ? 'btn btn-sm btn-light-success fw-boldest mb-3'
                  : 'btn btn-sm btn-light-danger fw-boldest mb-3'
              "
              data-toggle="tooltip"
              :title="`Last year : ${formatPrice(totalLastYear)}`"
            >
              <i
                :class="
                  compareValue === 0
                    ? 'bi bi-arrow-right'
                    : compareValue > 0
                    ? 'bi bi-arrow-up'
                    : 'bi bi-arrow-down'
                "
              ></i
              >{{ compareValue.toFixed(1) }} %
            </button>
          </div>
          <div class="col-12">
            <div class="col fs-5" style="color: grey">
              Total Sales This Year
            </div>
          </div>
        </div>
      </div>

      <div class="tab-content pt-8" v-if="isEmpty !== 0">
        <div
          class="tab-pane fade active show"
          id="kt_charts_widget_3_tab_pane_1"
        >
          <apexchart
            class="card-rounded-bottom h-125px"
            type="area"
            height="297"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </div>
      </div>

      <div
        class="tab-content pt-8"
        v-if="isEmpty === 0"
        style="margin-top: 50px; display: flex; justify-content: center"
      >
        ไม่มีข้อมูล
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    inputDateSubtractTen: Array,
    inputDateLastYearSubtractTen: Array,
    searchTenMonth: Array,
    searchTenMonthLastYear: Array,
    searchSalesThisYear: Array,
    searchSalesThisYearLastYear: Array,
  },
  components: {},
  setup() {
    const chartOptions = {
      fill: {
        colors: ["#00e396"],
      },
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        colors: ["#00e396"],
      },
      xaxis: {
        // categories: [
        //   "Jan",
        //   "Feb",
        //   "Mar",
        //   "May",
        //   "Jun",
        //   "Jul",
        //   "Aug",
        //   "Sep",
        //   "Oct",
        //   "Nov",
        //   "Dec",
        // ],
        categories: [],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
        y: {
          formatter: function (val) {
            return numbro(val).format({
              thousandSeparated: true,
            });
          },
        },
      },
    };

    const series = [
      {
        name: "มูลค่า",
        // data: [31, 40, 28, 51, 42, 109, 100, 40, 28, 51],
        data: [],
      },
    ];

    const series2 = [
      {
        name: "series1",
        // data: [31, 40, 28, 51, 42, 109, 100, 40, 28, 51],
        data: [],
      },
    ];

    return {
      chartOptions,
      series,
      series2,
    };
  },

  data: () => ({
    isShow: false,
    isEmpty: 0,

    reportDate: [],
    reportDateLastYear: [],

    total: 0,
    totalLastYear: 0,

    compareValue: 0,
  }),

  async created() {
    await this.search();
    await this.searchLastYear();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      this.handleDate();
    },

    async searchLastYear() {
      this.handleDate1(this.searchByDayLastYear);
    },

    // current year
    handleDate() {
      this.isShow = false;

      const newDateStart = dayjs(this.inputDateSubtractTen[0].$d).startOf(
        "month"
      );
      const newDateEnd = dayjs(this.inputDateSubtractTen[1].$d).endOf("month");

      const newFormatStart = dayjs(newDateStart.$d).format("YYYY-MM-DD");
      const newFormatEnd = dayjs(newDateEnd.$d).format("YYYY-MM-DD");

      let countMonth = dayjs(newFormatStart).diff(dayjs(newFormatEnd), "month");

      countMonth < 0 ? (countMonth *= -1) : countMonth;

      this.setXChart(newDateStart, newDateEnd, countMonth);
    },

    setXChart(newDateStart, newDateEnd, countMonth) {
      let newArrDate = [];
      let newArrDate2 = [];

      for (let index = 0; index < countMonth; index++) {
        newArrDate.push(
          dayjs(newDateEnd)
            .subtract(index, "month")
            .format("YYYY-MMMM")
            .substring(5, 8)
        );

        newArrDate2.push(
          dayjs(newDateEnd).subtract(index, "month").format("YYYY-MM")
        );
      }

      newArrDate = newArrDate.reverse();

      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: newArrDate,
          convertedCatToNumeric: false,
        },
      };

      this.handleDataSeries(newArrDate2);
    },

    handleDataSeries(newArrDate2) {
      let newArr = newArrDate2;

      if (this.searchSalesThisYear.length) {
        newArrDate2.forEach((element, index) => {
          const month = element.substring(5, 7);

          // sales
          if (this.searchSalesThisYear[0].length) {
            this.searchSalesThisYear[0].forEach((element2) => {
              const monthSales = dayjs(element2.createdAt).format("MM");
              if (month === monthSales) {
                newArr[index] = {
                  total: newArr[index].total
                    ? newArr[index].total + element2.final_price
                    : 0 + element2.final_price,
                };
              }
            });
          }

          // pos
          if (this.searchSalesThisYear[1].length) {
            this.searchSalesThisYear[1].forEach((element3) => {
              const monthSales = dayjs(element3.createdAt).format("MM");
              if (month === monthSales) {
                newArr[index] = {
                  total: newArr[index].total
                    ? newArr[index].total + parseFloat(element3.final_price)
                    : 0 + parseFloat(element3.final_price),
                };
              }
            });
          }
        });
      }

      this.setSeries(newArr);
    },

    setSeries(newArr) {
      newArr.forEach((element) => {
        if (element.total) {
          this.series[0].data.push(element.total);
        } else {
          this.series[0].data.push(0);
        }
      });

      this.series[0].data = this.series[0].data.reverse();

      this.total = this.series[0].data.reduce((sum, data) => sum + data, 0);

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },

    // last year
    handleDate1() {
      this.isShow = false;

      const newDateStart = dayjs(
        this.inputDateLastYearSubtractTen[0].$d
      ).startOf("month");
      const newDateEnd = dayjs(this.inputDateLastYearSubtractTen[1].$d).endOf(
        "month"
      );

      const newFormatStart = dayjs(newDateStart.$d).format("YYYY-MM-DD");
      const newFormatEnd = dayjs(newDateEnd.$d).format("YYYY-MM-DD");

      let countMonth = dayjs(newFormatStart).diff(dayjs(newFormatEnd), "month");

      countMonth < 0 ? (countMonth *= -1) : countMonth;

      this.setXChart1(newDateStart, newDateEnd, countMonth);
    },

    setXChart1(newDateStart, newDateEnd, countMonth) {
      let newArrDate = [];
      let newArrDate2 = [];

      for (let index = 0; index < countMonth; index++) {
        newArrDate.push(
          dayjs(newDateEnd)
            .subtract(index, "month")
            .format("YYYY-MMMM")
            .substring(5, 8)
        );

        newArrDate2.push(
          dayjs(newDateEnd).subtract(index, "month").format("YYYY-MM")
        );
      }

      newArrDate = newArrDate.reverse();

      // this.chartOptions = {
      //   ...this.chartOptions,
      //   xaxis: {
      //     ...this.chartOptions.xaxis,
      //     categories: newArrDate,
      //     convertedCatToNumeric: false,
      //   },
      // };

      this.handleDataSeries1(newArrDate2);
    },

    handleDataSeries1(newArrDate2) {
      let newArr = newArrDate2;

      if (this.searchSalesThisYearLastYear.length) {
        newArrDate2.forEach((element, index) => {
          const month = element.substring(5, 7);

          // sales
          if (this.searchSalesThisYearLastYear[0].length) {
            this.searchSalesThisYearLastYear[0].forEach((element2) => {
              const monthSales = dayjs(element2.createdAt).format("MM");
              if (month === monthSales) {
                newArr[index] = {
                  total: newArr[index].total
                    ? newArr[index].total + element2.final_price
                    : 0 + element2.final_price,
                };
              }
            });
          }

          // pos
          if (this.searchSalesThisYearLastYear[1].length) {
            this.searchSalesThisYearLastYear[1].forEach((element3) => {
              const monthSales = dayjs(element3.createdAt).format("MM");
              if (month === monthSales) {
                newArr[index] = {
                  total: newArr[index].total
                    ? newArr[index].total + parseFloat(element3.final_price)
                    : 0 + parseFloat(element3.final_price),
                };
              }
            });
          }
        });
      }

      this.setSeries1(newArr);
    },

    setSeries1(newArr) {
      newArr.forEach((element) => {
        if (element.total) {
          this.series2[0].data.push(element.total);
        } else {
          this.series2[0].data.push(0);
        }
      });

      this.series2[0].data = this.series2[0].data.reverse();

      this.totalLastYear = this.series2[0].data.reduce(
        (sum, data) => sum + data,
        0
      );

      this.compareLastYear();
    },

    compareLastYear() {
      if (this.totalLastYear === 0) {
        this.compareValue = 100;
      } else {
        this.compareValue =
          ((parseFloat(this.total) - parseFloat(this.totalLastYear)) /
            parseFloat(this.totalLastYear)) *
          100;
      }

      this.checkEmpty();

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },

    checkEmpty() {
      // this.isEmpty = 0;
      this.isEmpty = this.series[0].data.reduce((sum, data) => sum + data.y, 0);
    },
  },
});
</script>
