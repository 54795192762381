<template>
  <div :class="widgetClasses" class="card">
    <div class="card-body p-0" v-if="!isShow" style="height: 235px"></div>

    <div
      v-if="isShow"
      :class="
        isEmpty === 0
          ? 'card-body p-0'
          : 'card-body p-0 d-flex justify-content-between flex-column'
      "
      style="height: 235px"
    >
      <div style="padding: 24px 27px 0px 27px">
        <div class="me-2 flex-grow-1">
          <div class="fs-3 mb-2">
            <span class="fw-boldest"
              >{{
                formatPrice(
                  reportDate[reportDate.length - 1].sales
                    ? reportDate[reportDate.length - 1].sales
                    : 0
                )
              }}{{ " " }}</span
            >

            <button
              style="padding: 5px"
              :class="
                compareValue === 0
                  ? 'btn btn-sm btn-light-primary fw-boldest'
                  : compareValue > 0
                  ? 'btn btn-sm btn-light-success fw-boldest'
                  : 'btn btn-sm btn-light-danger fw-boldest'
              "
              data-toggle="tooltip"
              :title="`Last year : ${formatPrice(totalLastYear)}`"
            >
              <i
                :class="
                  compareValue === 0
                    ? 'bi bi-arrow-right'
                    : compareValue > 0
                    ? 'bi bi-arrow-up'
                    : 'bi bi-arrow-down'
                "
              ></i
              >{{ compareValue.toFixed(1) }} %
            </button>
          </div>

          <div class="fw-bold fs-6 text-gray-600">ยอดขายวันนี้</div>
        </div>
      </div>

      <div v-if="isEmpty !== 0" class="pt-1" style="margin-bottom: -52.72px">
        <apexchart
          type="bar"
          height="173"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <div
        v-if="isEmpty === 0"
        style="margin-top: 50px; display: flex; justify-content: center"
      >
        ไม่มีข้อมูล
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

import reportApi from "@/api/report/";

import { dataReport1 } from "@/core/report/report1/dataReport1";
import { filterTimeByHour } from "@/utils/dashboard/chart1/filterReport1";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    color: String,

    searchByDay: Array,
    searchByDayLastYear: Array,
  },
  components: {},
  setup(props) {
    const color = ref(props.color);

    const series = [
      {
        name: "ยอดขาย",
        data: [],
      },
    ];

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["40%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      // xaxis: false,
      xaxis: {
        crosshairs: {
          show: false,
        },
        categories: ["", "", "", "", "", "", ""],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: "black",
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        crosshairs: {
          show: false,
        },
        labels: {
          show: false,
          // style: {
          //   colors: getCSSVariableValue("--bs-gray-400"),
          //   fontSize: "12px",
          // },
        },
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      fill: {
        opacity: 1,
        colors: ["#7337EE"],
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

          return (
            "<ul class='mt-2 me-2'>" +
            "<li><b>ช่วงเวลา</b> : " +
            data.time +
            "</li>" +
            "<li><b>ยอดขาย</b> : " +
            numbro(data.y).format({
              thousandSeparated: true,
              // mantissa: 2,
            }) +
            "</li>" +
            "</ul>"
          );
        },
      },
      colors: [getCSSVariableValue("--bs-primary")],
      grid: {
        borderColor: getCSSVariableValue("--bs-gray-300"),
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    };

    const series2 = [
      {
        name: "Profit",
        data: [25, 30, 25, 45, 30, 40, 30],
      },
    ];

    const currentColor = computed(() => {
      return color.value ? color.value : "primary";
    });

    return {
      currentColor,
      chartOptions,
      series,
    };
  },

  data: () => ({
    isShow: false,
    isEmpty: 0,

    reportDate: [],
    reportDateLastYear: [],

    total: 0,
    totalLastYear: 0,

    compareValue: 0,
  }),

  async created() {
    this.reportDate = dataReport1();

    await this.search();
    await this.searchLastYear();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      this.handleFilterReport(this.searchByDay);
    },

    async searchLastYear() {
      this.handleFilterReport1(this.searchByDayLastYear);
    },

    // current year
    async handleFilterReport(item) {
      if (item.length) {
        let newItemSales = [];
        let newItemPos = [];
        this.reportDate = dataReport1();

        // set hour sales order
        item[0].forEach((element, index) => {
          newItemSales.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        // set hour POS
        item[1].forEach((element, index) => {
          newItemPos.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        const newData = await filterTimeByHour(
          newItemSales,
          this.reportDate,
          newItemPos
        );

        if (newData.length > 0) {
          this.reportDate = newData;
          this.dataChart1 = newData;
          this.dataChart2 = newData;
          this.setDateAvg(newData);
        }

        this.loading = false;
      }
    },

    setDateAvg(item) {
      let sumSales = 0;
      let sumCusAmt = 0;
      let sumBilAvg = 0;
      let sumProAmt = 0;

      item.forEach((element) => {
        sumSales += element.sales;
        sumCusAmt += element.customerAmt;
        sumBilAvg += element.salesPerBill;
        sumProAmt += element.productAmt;
      });

      this.reportDate.push({
        time: "รวม",
        sales: sumSales,
        customerAmt: sumCusAmt,
        salesPerBill: sumCusAmt === 0 ? 0 : sumSales / sumCusAmt,
        productAmt: sumProAmt,
      });

      this.total = sumSales;

      // this.compareLastYear();

      this.filterSevenHr();
    },

    filterSevenHr() {
      this.reportDate.forEach((element, index) => {
        if (index < this.reportDate.length - 1) {
          this.reportDate[index] = {
            ...this.reportDate[index],
            sort: index,
          };
        }
      });

      // this.reportDate = this.reportDate
      //   .sort((a, b) => b.sort - a.sort)
      //   .map((data, index, array) => data);

      let firstSales = "";
      let findIndexData = 0;

      this.reportDate = this.reportDate
        .sort((a, b) => b.sort - a.sort)
        .map((data, index, array) => data);

      firstSales = this.reportDate.find((data) => data.sales > 0);

      if (firstSales) {
        findIndexData = this.reportDate.findIndex(
          (data) => data.sort === firstSales.sort
        );
      }

      for (let i = findIndexData; i < findIndexData + 7; i++) {
        const element = this.reportDate[i];

        if (element.time) {
          this.series[0].data.push({
            sort: i,
            time: element.time,
            x: "",
            y: element.sales,
          });
        }
      }

      this.series[0].data = this.series[0].data
        .sort((a, b) => b.sort - a.sort)
        .map((data, index, array) => data);

      // this.series[0].data = this.series[0].data
      //   .sort((a, b) => a.sort - b.sort)
      //   .map((data, index, array) => data);
    },

    // last year
    async handleFilterReport1(item) {
      if (item.length) {
        let newItemSales = [];
        let newItemPos = [];
        this.reportDateLastYear = dataReport1();

        // set hour sales order
        item[0].forEach((element, index) => {
          newItemSales.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        // set hour POS
        item[1].forEach((element, index) => {
          newItemPos.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        const newData = await filterTimeByHour(
          newItemSales,
          this.reportDateLastYear,
          newItemPos
        );

        if (newData.length > 0) {
          this.reportDateLastYear = newData;
          this.dataChart1 = newData;
          this.dataChart2 = newData;
          this.setDateAvg1(newData);
        }

        this.loading = false;
      }
    },

    setDateAvg1(item) {
      let sumSales = 0;
      let sumCusAmt = 0;
      let sumBilAvg = 0;
      let sumProAmt = 0;

      item.forEach((element) => {
        sumSales += element.sales;
        sumCusAmt += element.customerAmt;
        sumBilAvg += element.salesPerBill;
        sumProAmt += element.productAmt;
      });

      this.reportDateLastYear.push({
        time: "รวม",
        sales: sumSales,
        customerAmt: sumCusAmt,
        salesPerBill: sumCusAmt === 0 ? 0 : sumSales / sumCusAmt,
        productAmt: sumProAmt,
      });

      this.totalLastYear = sumSales;

      this.compareLastYear();
    },

    // compare last year
    compareLastYear() {
      if (this.totalLastYear === 0) {
        this.compareValue = 100;
      } else {
        this.compareValue =
          ((parseFloat(this.total) - parseFloat(this.totalLastYear)) /
            parseFloat(this.totalLastYear)) *
          100;
      }

      this.checkEmpty();

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },

    checkEmpty() {
      // this.isEmpty = 0;
      this.isEmpty = this.series[0].data.reduce((sum, data) => sum + data.y, 0);
    },
  },
});
</script>

<style>
ul li::marker {
  color: #7337ee;
  font-size: 1.5em;
}
</style>
