<template>
  <!--begin::Table Widget 1-->
  <div
    :class="widgetClasses"
    class="card"
    style="height: 660px; max-height: 660px"
  >
    <!--begin::Header-->
    <div class="card-header border-0 pt-5 pb-3">
      <!--begin::Card title-->
      <h2 class="card-title align-items-start flex-column">
        <span class="card-label fw-boldest text-gray-800 fs-2"
          >Stock Report</span
        >

        <span class="text-gray-600 fw-bold mt-2 fs-6"
          >Total {{ formatThousand(total) }} Items in the Stock</span
        >
      </h2>
      <!--end::Card title-->

      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Search-->

        <!--end::Search-->

        <div class="my-1 pe-6">
          <!--begin::Select-->
          <label for="">Category</label>
          <select
            v-model="cateSelected"
            class="form-select form-select-sm form-select-solid fw-bolder w-125px"
            data-control="select2"
            data-placeholder="All Users"
            data-hide-search="true"
            @change="onCateChang()"
          >
            <option value="all" selected>Show All</option>
            <option v-for="item in categories" :key="item" :value="item.id">
              {{ item.main_product_group_name }}
            </option>
          </select>
          <!--end::Select-->
        </div>

        <div class="my-1 pe-6">
          <!--begin::Select-->
          <label for="">Status</label>
          <select
            v-model="statusSelected"
            class="form-select form-select-sm form-select-solid fw-bolder w-125px"
            data-control="select2"
            data-placeholder="All Users"
            data-hide-search="true"
          >
            <option value="all" selected>Show All</option>
            <option value="in">In Stock</option>
            <option value="out">Out Of Stock</option>
          </select>
          <!--end::Select-->
        </div>

        <div class="position-relative pe-6 my-1">
          <label for=""></label>
          <button @click="filterBySelect" class="btn btn-sm btn-light mt-7">
            View Stock
          </button>
        </div>
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table align-middle table-row-bordered table-row-dashed gy-5"
          id="kt_table_widget_1"
        >
          <!--begin::Table body-->
          <tbody>
            <!--begin::Table row-->
            <tr class="text-start text-gray-600 fw-boldest fs-5 text-uppercase">
              <th class="min-w-100px text-center">ITEM</th>
              <th class="min-w-100px text-center">PRODUCT ID</th>
              <th class="min-w-100px text-center">PRICE</th>
              <th class="min-w-100px text-center">STATUS</th>
              <th class="text-end text-center">QTY</th>
            </tr>
            <!--end::Table row-->

            <template v-for="(item, i) in dataItemsShow" :key="i">
              <tr>
                <td style="padding: 3px 0px" class="fw-boldest">
                  {{ item.product_name }}
                </td>
                <td style="padding: 3px 0px">
                  {{ item.product_code }}
                </td>

                <td class="text-end" style="padding: 3px 0px">
                  {{ formatPrice(item.product_retail_price) }}
                </td>

                <td class="text-center" style="padding: 3px 0px">
                  <button
                    style="padding: 5px; width: 100px; cursor: default"
                    :class="item.product_bring_qty > 0 ? class2 : class3"
                  >
                    {{
                      item.product_bring_qty > 0 ? "In Stock" : "Out Of Stock"
                    }}
                  </button>
                </td>

                <td class="text-end" style="padding: 3px 0px">
                  {{ formatThousand(item.product_bring_qty) }}
                </td>
                <!--end::Action--->
              </tr>

              <!--end::Table row-->
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
  <!--end::Table Widget 1-->
</template>

<script>
import { defineComponent, ref } from "vue";
import Pagination from "../../../views/components/pagination.vue";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    stockReport: Array,
    categories: Array,
  },
  components: { Pagination },
  setup() {
    // const all = ref < boolean > false;
    const items = [
      {
        code: "100101",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100102",
        logo: "media/svg/brand-logos/leaf.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "C#, ASP.NET, MS SQL ",
        company: "Agoda",
        skills: "Houses & Hotels",
        progress: "85%",
        color: "primary",
      },
      {
        code: "100103",
        logo: "media/svg/brand-logos/atica.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "PHP, Laravel, VueJS",
        company: "RoadGee",
        skills: "Transportation",
        progress: "40%",
        color: "success",
      },
      {
        code: "100104",
        logo: "media/svg/brand-logos/volicity-9.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "Python, ReactJS",
        company: "The Hill",
        skills: "Insurance",
        progress: "71%",
        color: "info",
      },
      {
        code: "100105",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100106",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100107",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
    ];

    const date = new Date();
    let newDate = date.toDateString();
    newDate.substring(1, 10);

    return {
      items,
      newDate,
    };
  },
  data: () => ({
    dataItemsShow: [],
    tableItems: [],

    // filterItems: "",

    cateSelected: "all",
    statusSelected: "all",

    oldData: [],

    class1: "btn btn-sm btn-light-success fw-boldest fs-6 ",
    class2: "btn btn-sm btn-light-info fw-boldest fs-6 ",
    class3: "btn btn-sm btn-light-warning fw-boldest fs-6 ",

    total: 0,
  }),

  async created() {
    await this.search();
  },

  methods: {
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY");

        return oldDate;
      } else {
        return "-";
      }
    },
    formatThousand(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
        });
        return newVal;
      }
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      this.tableItems = this.stockReport[0].warehouseSnapshotItems;
      this.dataItemsShow = this.stockReport[0].warehouseSnapshotItems;
      this.oldData = this.stockReport[0].warehouseSnapshotItems;

      this.sumTotal();
    },

    sumTotal() {
      this.total = this.tableItems.reduce(
        (sum, data) => sum + data.product_bring_qty,
        0
      );
    },

    filterBySelect() {
      if (this.cateSelected === "all") {
        if (this.statusSelected === "in") {
          const filterData = this.oldData.filter(
            (data) => data.product_bring_qty !== 0
          );

          this.tableItems = filterData;
          this.dataItemsShow = filterData;
        }

        if (this.statusSelected === "out") {
          const filterData = this.oldData.filter(
            (data) => data.product_bring_qty === 0
          );

          this.tableItems = filterData;
          this.dataItemsShow = filterData;
        }
      } else {
        if (this.statusSelected === "all") {
          const filterData = this.oldData.filter(
            (data) => data.mainProductGroupId === this.cateSelected
          );

          this.tableItems = filterData;
          this.dataItemsShow = filterData;
        }

        if (this.statusSelected === "in") {
          const filterData = this.oldData.filter(
            (data) =>
              data.product_bring_qty !== 0 &&
              data.mainProductGroupId === this.cateSelected
          );

          this.tableItems = filterData;
          this.dataItemsShow = filterData;
        }

        if (this.statusSelected === "out") {
          const filterData = this.oldData.filter(
            (data) =>
              data.product_bring_qty === 0 &&
              data.mainProductGroupId === this.cateSelected
          );

          this.tableItems = filterData;
          this.dataItemsShow = filterData;
        }
      }

      this.sumTotal();
    },
  },
});
</script>
