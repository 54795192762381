<template>
  <div :class="widgetClasses" class="card">
    <div class="card-body p-0" v-if="!isShow"></div>
    <div class="card-body p-0" v-if="isShow">
      <div style="padding: 24px 27px 0px 27px">
        <div class="me-2 flex-grow-1">
          <div class="d-flex flex-column">
            <h2 class="text-dark mb-1 fs-2 fw-boldest">Highlights</h2>
          </div>
        </div>
      </div>

      <div class="px-10" style="margin-top: -40px; height: 215px">
        <div class="table-responsive">
          <table
            class="table align-middle table-row-bordered table-row-dashed gy-5 mt-13"
            id="kt_table_widget_1"
          >
            <tbody>
              <tr>
                <td>Avg. Bill</td>
                <td>
                  <i
                    :style="
                      compareAvgBill > 0
                        ? 'color: #50cd89; font-size: 16px'
                        : 'color: #f1416c; font-size: 16px'
                    "
                    :class="
                      compareAvgBill === 0
                        ? 'bi bi-arrow-right'
                        : compareAvgBill > 0
                        ? 'bi bi-arrow-up-right'
                        : 'bi bi-arrow-down-left'
                    "
                  ></i>
                  {{ formatPrice(avgBill) }}
                </td>
              </tr>

              <tr>
                <td>Qty Items</td>
                <td>
                  <i
                    :style="
                      compareQtyItems > 0
                        ? 'color: #50cd89; font-size: 16px'
                        : 'color: #f1416c; font-size: 16px'
                    "
                    :class="
                      compareQtyItems > 0
                        ? 'bi bi-arrow-up-right'
                        : 'bi bi-arrow-down-left'
                    "
                  ></i>
                  {{ qtyItems }}
                </td>
              </tr>

              <tr>
                <td>Out of Stock</td>
                <td>
                  <i
                    :style="
                      compareOutOfStock === 0
                        ? 'color: ; font-size: 16px'
                        : compareOutOfStock > 0
                        ? 'color: #50cd89; font-size: 16px'
                        : 'color: #f1416c; font-size: 16px'
                    "
                    :class="
                      compareOutOfStock === 0
                        ? 'bi bi-arrow-right'
                        : compareOutOfStock > 0
                        ? 'bi bi-arrow-up-right'
                        : 'bi bi-arrow-down-left'
                    "
                  ></i>
                  {{ outOfStock }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

import { dataReport1 } from "@/core/report/report1/dataReport1";
import { filterTimeByHour } from "@/utils/dashboard/chart3/filterReport1";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    color: String,

    searchHightLights: Array,
    searchHightLightsLastYear: Array,

    searchOutOfStock: Array,
    searchOutOfStockLastYear: Array,
  },
  components: {},
  setup(props) {
    const items = [
      {
        logo: "media/svg/brand-logos/aven.svg",
        name: "รายการขายปลีก",
        tools: "Retail Sales",
        company: "1,067",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
        percent: "1.5%",
      },
      {
        logo: "media/svg/brand-logos/leaf.svg",
        name: "รายการขายส่ง",
        tools: "Whole Sales",
        company: "24,588",
        skills: "Houses & Hotels",
        progress: "85%",
        color: "primary",
        percent: "2.5%",
      },
      {
        logo: "media/svg/brand-logos/atica.svg",
        name: "รายการซื้อ",
        tools: "Purchases",
        company: "974",
        skills: "Transportation",
        progress: "40%",
        color: "success",
        percent: "3%",
      },
    ];

    return {
      items,
    };
  },

  data: () => ({
    isShow: false,

    reportDate: [],
    reportDateLastYear: [],

    total: 0,
    totalLastYear: 0,

    compareValue: 0,

    avgBill: 0,
    qtyItems: 0,
    outOfStock: 0,

    avgBillLastYear: 0,
    qtyItemsLastYear: 0,
    outOfStockLastYear: 0,

    compareAvgBill: 0,
    compareQtyItems: 0,
    compareOutOfStock: 0,
  }),

  async created() {
    this.reportDate = dataReport1();

    await this.search();
    await this.searchLastYear();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
        });
        return newVal;
      }
    },

    async search() {
      this.handleFilterReport(this.searchHightLights);
      this.handleOutOfStock();
    },

    async searchLastYear() {
      this.handleFilterReport1(this.searchHightLightsLastYear);
      this.handleOutOfStock1();
    },

    // current year
    handleOutOfStock() {
      this.searchOutOfStock.forEach((element) => {
        if (element.product_bring_qty === 0) {
          this.outOfStock = this.outOfStock + 1;
        }
      });
    },

    async handleFilterReport(item) {
      if (item.length) {
        let newItemSales = [];
        let newItemPos = [];
        this.reportDate = dataReport1();

        // set hour sales order
        item[0].forEach((element, index) => {
          newItemSales.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        // set hour POS
        item[1].forEach((element, index) => {
          newItemPos.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        const newData = await filterTimeByHour(
          newItemSales,
          this.reportDate,
          newItemPos
        );

        if (newData.length > 0) {
          this.reportDate = newData;
          this.dataChart1 = newData;
          this.dataChart2 = newData;
          this.setDateAvg(newData);
        }

        this.loading = false;
      }
    },

    setDateAvg(item) {
      let sumSales = 0;
      let sumCusAmt = 0;
      let sumBilAvg = 0;
      let sumProAmt = 0;

      item.forEach((element) => {
        sumSales += element.sales;
        sumCusAmt += element.customerAmt;
        sumBilAvg += element.salesPerBill;
        sumProAmt += element.productAmt;
      });

      this.reportDate.push({
        time: "รวม",
        sales: sumSales,
        customerAmt: sumCusAmt,
        salesPerBill: sumCusAmt === 0 ? 0 : sumSales / sumCusAmt,
        productAmt: sumProAmt,
      });

      this.setHightLights();
    },

    setHightLights() {
      this.avgBill = this.reportDate[this.reportDate.length - 1].salesPerBill;
      this.qtyItems = this.reportDate[this.reportDate.length - 1].productAmt;
    },

    // last year
    handleOutOfStock1() {
      this.searchOutOfStockLastYear.forEach((element) => {
        if (element.product_bring_qty === 0) {
          this.outOfStockLastYear = this.outOfStockLastYear + 1;
        }
      });
    },

    async handleFilterReport1(item) {
      if (item.length) {
        let newItemSales = [];
        let newItemPos = [];
        this.reportDateLastYear = dataReport1();

        // set hour sales order
        item[0].forEach((element, index) => {
          newItemSales.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        // set hour POS
        item[1].forEach((element, index) => {
          newItemPos.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        const newData = await filterTimeByHour(
          newItemSales,
          this.reportDateLastYear,
          newItemPos
        );

        if (newData.length > 0) {
          this.reportDateLastYear = newData;
          this.dataChart1 = newData;
          this.dataChart2 = newData;
          this.setDateAvg1(newData);
        }

        this.loading = false;
      }
    },

    setDateAvg1(item) {
      let sumSales = 0;
      let sumCusAmt = 0;
      let sumBilAvg = 0;
      let sumProAmt = 0;

      item.forEach((element) => {
        sumSales += element.sales;
        sumCusAmt += element.customerAmt;
        sumBilAvg += element.salesPerBill;
        sumProAmt += element.productAmt;
      });

      this.reportDateLastYear.push({
        time: "รวม",
        sales: sumSales,
        customerAmt: sumCusAmt,
        salesPerBill: sumCusAmt === 0 ? 0 : sumSales / sumCusAmt,
        productAmt: sumProAmt,
      });

      this.setHightLights1();
    },

    setHightLights1() {
      this.avgBillLastYear =
        this.reportDateLastYear[
          this.reportDateLastYear.length - 1
        ].salesPerBill;

      this.qtyItemsLastYear =
        this.reportDateLastYear[this.reportDateLastYear.length - 1].productAmt;

      this.compareLastYear();
    },

    compareLastYear() {
      if (this.avgBillLastYear === 0) {
        this.compareAvgBill = 100;
      } else {
        this.compareAvgBill =
          ((parseFloat(this.avgBill) - parseFloat(this.avgBillLastYear)) /
            parseFloat(this.avgBillLastYear)) *
          100;
      }

      if (this.qtyItemsLastYear === 0) {
        this.compareQtyItems = 100;
      } else {
        this.compareQtyItems =
          ((parseFloat(this.qtyItems) - parseFloat(this.qtyItemsLastYear)) /
            parseFloat(this.qtyItemsLastYear)) *
          100;
      }

      if (this.outOfStockLastYear === 0) {
        this.compareOutOfStock = 100;
      } else {
        this.compareOutOfStock =
          ((parseFloat(this.outOfStock) - parseFloat(this.outOfStockLastYear)) /
            parseFloat(this.outOfStockLastYear)) *
          100;
      }

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },
  },
});
</script>
