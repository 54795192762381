<template>
  <div :class="widgetClasses" class="card">
    <div class="card-body p-0" v-if="!isShow" style="height: 235px"></div>

    <div class="card-body p-0" v-if="isShow" style="height: 235px">
      <div style="padding: 24px 27px 0px 27px">
        <div class="me-2 flex-grow-1">
          <div class="fs-3 mb-2">
            <!-- <span style="color: grey">฿ </span> -->
            <span class="fw-boldest"
              >{{
                formatPrice(
                  this.reportDate[this.reportDate.length - 1].customerAmt
                )
              }}{{ " " }}
            </span>

            <button
              style="padding: 5px"
              :class="
                compareValue === 0
                  ? 'btn btn-sm btn-light-primary fw-boldest'
                  : compareValue > 0
                  ? 'btn btn-sm btn-light-success fw-boldest'
                  : 'btn btn-sm btn-light-danger fw-boldest'
              "
              data-toggle="tooltip"
              :title="`Last year : ${formatPrice(totalLastYear)}`"
            >
              <i
                :class="
                  compareValue === 0
                    ? 'bi bi-arrow-right'
                    : compareValue > 0
                    ? 'bi bi-arrow-up'
                    : 'bi bi-arrow-down'
                "
              ></i
              >{{ compareValue.toFixed(1) }} %
            </button>
          </div>

          <div class="fw-bold fs-6 text-gray-600">จำนวนบิลลูกค้าวันนี้</div>
        </div>
      </div>

      <div v-if="isEmpty !== 0" class="pt-7 pb-4">
        <!-- <div class="pt-7 pb-4"> -->
        <apexchart
          type="donut"
          width="280"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>

      <div
        v-if="isEmpty === 0"
        style="margin-top: 50px; display: flex; justify-content: center"
      >
        ไม่มีข้อมูล
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

import whApi from "@/api/warehouse/";
import reportApi from "@/api/report/";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

import { dataReport1 } from "@/core/report/report1/dataReport1";
import { filterTimeByHour } from "@/utils/dashboard/chart2/filterReport1";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    color: String,

    categories: Array,

    searchByDay: Array,
    searchByDayLastYear: Array,
  },
  components: {},
  setup(props) {
    // const color = ref(props.color);

    const series = [];

    const chartOptions = {
      chart: {
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: ["#E6E6FA"],
        },
        formatter: function (val, opts) {
          let newVal = opts.w.config.series[opts.seriesIndex];
          return numbro(newVal).format({
            thousandSeparated: true,
            // mantissa: 2,
          });
        },
      },
      fill: {
        type: "gradient",
        // colors: ["#E91E63", "#7337EE", "#DCDCDC"],
        colors: [],
      },
      colors: [],
      // colors: ["#E91E63", "#7337EE", "#DCDCDC"],
      // labels: ["ยา", "อาหารเสริม", "เวชสำอาง"],
      labels: [],

      // options: {
      //   legend: {
      //     position: "bottom",
      //   },
      // },
      legend: {
        show: true,

        formatter: function (seriesName, opts) {
          // return [seriesName, " : ", opts.w.globals.series[opts.seriesIndex]];
          if (seriesName.length > 16) {
            return seriesName.substring(0, 16) + "...";
          } else {
            return seriesName;
          }
        },

        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },

      stroke: {
        // colors: ["#E91E63", "#7337EE", "#DCDCDC"],
        width: 1,
      },

      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    };

    return {
      chartOptions,
      series,
    };
  },

  data: () => ({
    isShow: false,

    category: [],

    reportDate: [],
    reportDateLastYear: [],

    colorsTheme: [
      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },

      { text: "d63384" },
      { text: "0dcaf0" },
      { text: "ffc107" },
      { text: "20c997" },
      { text: "6610f2" },
      { text: "009ef6" },
      { text: "6f42c1" },
      { text: "fd7e14" },
      { text: "dc3545" },
      { text: "198754" },
    ],
    total: 0,
    totalLastYear: 0,

    compareValue: 0,
  }),

  async created() {
    this.reportDate = dataReport1();
    this.reportDateLastYear = dataReport1();

    this.category = this.categories;

    await this.search();
    await this.searchLastYear();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      this.handleFilterReport(this.searchByDay);
    },

    async searchLastYear() {
      this.handleFilterReport1(this.searchByDayLastYear);
    },

    //current year
    async handleFilterReport(item) {
      if (item.length) {
        let newItemSales = [];
        let newItemPos = [];
        this.reportDate = dataReport1();

        // set hour sales order
        item[0].forEach((element, index) => {
          newItemSales.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        // set hour POS
        item[1].forEach((element, index) => {
          newItemPos.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        const newData = await filterTimeByHour(
          newItemSales,
          this.reportDate,
          newItemPos
        );

        if (newData.length > 0) {
          this.reportDate = newData;
          this.dataChart1 = newData;
          this.dataChart2 = newData;
          this.setDateAvg(newData);
        }

        this.loading = false;
      }
    },

    setDateAvg(item) {
      let sumSales = 0;
      let sumCusAmt = 0;
      let sumBilAvg = 0;
      let sumProAmt = 0;

      item.forEach((element) => {
        sumSales += element.sales;
        sumCusAmt += element.customerAmt;
        sumBilAvg += element.salesPerBill;
        sumProAmt += element.productAmt;
      });

      this.reportDate.push({
        time: "รวม",
        sales: sumSales,
        customerAmt: sumCusAmt,
        salesPerBill: sumCusAmt === 0 ? 0 : sumSales / sumCusAmt,
        productAmt: sumProAmt,
      });

      this.total = sumCusAmt;

      this.handleDataByCate();
    },

    handleDataByCate() {
      let itemByCate = [];

      this.category.forEach((element, index) => {
        this.chartOptions.colors.push("#" + this.colorsTheme[index].text);

        this.chartOptions.labels.push(element.main_product_group_name);

        this.searchByDay[0].forEach((element2, index2) => {
          element2.salesOrderItems.forEach((element21) => {
            if (element21.product.mainProductGroupId === element.id) {
              itemByCate.push({
                mainId: element.id,
                name: element.main_product_group_name,
                amount: element21.item_amt,
              });
            }
          });
        });

        this.searchByDay[1].forEach((element3, index3) => {
          element3.pointOfSaleItems.forEach((element31) => {
            if (element31.product.mainProductGroupId === element.id) {
              itemByCate.push({
                mainId: element.id,
                name: element.main_product_group_name,
                amount: element31.item_amt,
              });
            }
          });
        });
      });

      this.chartOptions.fill.colors = this.chartOptions.colors;

      this.setSeries(itemByCate);
    },

    setSeries(item) {
      let newItem = item;
      let newIndex = "";

      let newArr = [];

      this.category.forEach((element, index) => {
        item.forEach((element2, index2) => {
          if (element2.mainId === element.id) {
            newIndex = this.category.findIndex(
              (data) => data.id === element.id
            );

            this.category[newIndex] = {
              ...this.category[newIndex],
              amount: element2.amount,
            };

            newArr.push(this.category[newIndex]);
          }
        });

        if (!element.amount) {
          newArr.push({ ...element, amount: 0 });
        }
      });

      this.filterDupCate(newArr);
    },

    filterDupCate(dupCate) {
      let newArr = [];
      let i = 0;

      const dup = dupCate.reduce((a, e) => {
        a[e.id] = ++a[e.id] || 0;
        return a;
      }, {});

      for (const [key, value] of Object.entries(dup)) {
        const newDup = dupCate.filter((e) => e.id === parseInt(key));
        const newSumAmt = newDup.reduce((sum, data) => sum + data.amount, 0);
        this.category[i] = { ...this.category[i], amount: newSumAmt };

        this.series.push(newSumAmt);

        i += 1;
      }

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },

    // last year
    async handleFilterReport1(item) {
      if (item.length) {
        let newItemSales = [];
        let newItemPos = [];
        this.reportDateLastYear = dataReport1();

        // set hour sales order
        item[0].forEach((element, index) => {
          newItemSales.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        // set hour POS
        item[1].forEach((element, index) => {
          newItemPos.push({
            ...element,
            newCreatedAt: moment(element.createdAt).format("H:mm"),
          });
        });

        const newData = await filterTimeByHour(
          newItemSales,
          this.reportDateLastYear,
          newItemPos
        );

        if (newData.length > 0) {
          this.reportDateLastYear = newData;
          this.dataChart1 = newData;
          this.dataChart2 = newData;
          this.setDateAvg1(newData);
        }

        this.loading = false;
      }
    },

    setDateAvg1(item) {
      let sumSales = 0;
      let sumCusAmt = 0;
      let sumBilAvg = 0;
      let sumProAmt = 0;

      item.forEach((element) => {
        sumSales += element.sales;
        sumCusAmt += element.customerAmt;
        sumBilAvg += element.salesPerBill;
        sumProAmt += element.productAmt;
      });

      this.reportDateLastYear.push({
        time: "รวม",
        sales: sumSales,
        customerAmt: sumCusAmt,
        salesPerBill: sumCusAmt === 0 ? 0 : sumSales / sumCusAmt,
        productAmt: sumProAmt,
      });

      this.totalLastYear = sumCusAmt;

      this.compareLastYear();
    },

    compareLastYear() {
      if (this.totalLastYear === 0) {
        this.compareValue = 100;
      } else {
        this.compareValue =
          ((parseFloat(this.total) - parseFloat(this.totalLastYear)) /
            parseFloat(this.totalLastYear)) *
          100;
      }

      this.checkEmpty();

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },

    checkEmpty() {
      // this.isEmpty = 0;
      this.isEmpty = this.series.reduce((sum, data) => sum + data, 0);
    },
  },
});
</script>
