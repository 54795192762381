<template>
  <!--begin::Table Widget 1-->
  <div
    :class="widgetClasses"
    class="card"
    style="height: 700px; max-height: 700px"
  >
    <!-- <div class="card-body pb-0 px-0"></div> -->

    <!--begin::Header-->
    <div class="card-header border-0 pt-5 pb-3">
      <!--begin::Card title-->
      <h2 class="card-title align-items-start flex-column">
        <span class="card-label fw-boldest text-gray-800 fs-2"
          >สินค้าขายดี</span
        >

        <span class="text-gray-600 fw-bold mt-2 fs-6"
          >รายการสินค้าขายดีประจำเดือน</span
        >
      </h2>
      <!--end::Card title-->

      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Search-->

        <!--end::Search-->

        <!-- <div class="my-1 pe-6">
          <label for="">Category</label>
          <select
            v-model="cateSelected"
            class="form-select form-select-sm form-select-solid fw-bolder w-125px"
            data-control="select2"
            data-placeholder="All Users"
            data-hide-search="true"
          >
            <option value="all" selected>Show All</option>
            <option v-for="item in categories" :key="item" :value="item.id">
              {{ item.main_product_group_name }}
            </option>
          </select>
        </div>

        <div class="my-1 pe-6">
          <label for="">Status</label>
          <select
            v-model="statusSelected"
            class="form-select form-select-sm form-select-solid fw-bolder w-125px"
            data-control="select2"
            data-placeholder="All Users"
            data-hide-search="true"
          >
            <option value="all" selected>Show All</option>
            <option value="in">In Stock</option>
            <option value="out">Out Of Stock</option>
          </select>
        </div>

        <div class="position-relative pe-6 my-1">
          <label for=""></label>
          <span
            class="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute translate-middle ms-6"
            style="margin-top: 37px"
          >
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            placeholder="Search"
            class="w-150px form-control form-control-sm form-control-solid ps-10"
          />
        </div>

        <div
          style="
            border: 1px solid Gainsboro;
            border-radius: 50%;
            padding: 3.5px 3.5px;
            background-color: #f6f8fa;
            cursor: pointer;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
              0 6px 20px 0 rgba(0, 0, 0, 0.19);
          "
          class="mt-6"
        >
          <inline-svg src="media/icons/duotune/general/gen021.svg" />
        </div> -->
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table align-middle table-row-bordered table-row-dashed gy-5"
          id="kt_table_widget_1"
        >
          <!--begin::Table body-->
          <tbody>
            <!--begin::Table row-->
            <tr class="text-start text-gray-600 fw-boldest fs-5 text-uppercase">
              <th class="min-w-100px text-center">รหัสสินค้า</th>
              <th class="min-w-100px text-center">ชื่อสินค้า</th>
              <th class="min-w-100px text-center">ราคาขาย</th>
              <th class="min-w-100px text-center">จำนวนขาย</th>
              <th class="min-w-100px text-center">รวมยอดขาย</th>
              <th class="text-end"></th>
              <th class="text-end"></th>
            </tr>
            <!--end::Table row-->

            <tr v-if="reportDate.length < 1">
              <td class="text-center" style="padding-top: 220px" colspan="8">
                ไม่มีข้อมูล
              </td>
            </tr>
            <template v-for="(item, i) in reportDate" :key="i">
              <tr>
                <td>
                  {{ item.product_code ? item.product_code : "" }}
                </td>
                <td class="fw-boldest">
                  {{ item.product_name ? item.product_name : "" }}
                </td>
                <td class="text-end">
                  {{ item.item_price ? item.item_price : "0" }}
                </td>
                <td class="text-end">
                  {{ item.item_amt ? item.item_amt : "0" }}
                </td>
                <td class="text-end">
                  {{ item.total ? item.total : "0" }}
                </td>

                <td>
                  <button
                    style="padding: 5px; width: 50px; cursor: default"
                    :class="
                      i + 1 > 5
                        ? class3
                        : i + 1 <= 3
                        ? class1
                        : i + 1 > 3
                        ? class2
                        : ''
                    "
                  >
                    #{{ i + 1 }}
                  </button>
                </td>

                <td class="pe-0 text-end">
                  <a class="btn btn-light text-muted fw-boldest btn-sm">+</a>
                </td>
                <!--end::Action--->
              </tr>
              <!--end::Table row-->
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
    <!-- <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div> -->
  </div>
  <!--end::Table Widget 1-->
</template>

<script>
import { defineComponent, ref } from "vue";

import Pagination from "../../../views/components/pagination.vue";
import { filterReport3 } from "@/utils/reports/report3/filterReport3";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    searchBestSeller: Array,
    pdItems: Array,
    categories: Array,
  },
  components: { Pagination },
  setup() {
    // const all = ref < boolean > false;
    const items = [
      {
        code: "100101",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100102",
        logo: "media/svg/brand-logos/leaf.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "C#, ASP.NET, MS SQL ",
        company: "Agoda",
        skills: "Houses & Hotels",
        progress: "85%",
        color: "primary",
      },
      {
        code: "100103",
        logo: "media/svg/brand-logos/atica.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "PHP, Laravel, VueJS",
        company: "RoadGee",
        skills: "Transportation",
        progress: "40%",
        color: "success",
      },
      {
        code: "100104",
        logo: "media/svg/brand-logos/volicity-9.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "Python, ReactJS",
        company: "The Hill",
        skills: "Insurance",
        progress: "71%",
        color: "info",
      },
      {
        code: "100105",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100106",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
      {
        code: "100107",
        logo: "media/svg/brand-logos/aven.svg",
        name: "Amoxy 500 mg 10 s",
        tools: "HTML, JS, ReactJS",
        company: "Intertico",
        skills: "Web, UI/UX Design",
        progress: "65%",
        color: "danger",
      },
    ];

    return {
      items,
      // all,
    };
  },
  data: () => ({
    tableItems: "",
    filterItems: "",

    isShow: false,
    isEmpty: 0,

    reportDate: [],

    total: 0,

    cateSelected: "all",
    statusSelected: "all",

    class1: "btn btn-sm btn-light-success fw-boldest",
    class2: "btn btn-sm btn-light-info fw-boldest",
    class3: "btn btn-sm btn-light-warning fw-boldest",
  }),

  async created() {
    await this.search();
  },

  methods: {
    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          // mantissa: 2,
        });
        return newVal;
      }
    },

    async search() {
      this.handleFilterReport(this.searchBestSeller);
    },

    async handleFilterReport() {
      const filterReport = await filterReport3(
        this.pdItems,
        this.searchBestSeller
      );

      this.setDataToTable(filterReport);
    },

    setDataToTable(items) {
      let { newPd, pdBestSellerByDate } = items;

      newPd.forEach((element, index) => {
        pdBestSellerByDate.forEach((element2, index2) => {
          if (element2.product_id === element.product_id) {
            newPd.splice(index, 1, element2);
          }
        });
      });

      pdBestSellerByDate.length ? (this.isEmpty = 1) : (this.isEmpty = 0);

      newPd.sort((a, b) => b.total - a.total);

      this.reportDate = newPd;

      // this.reportDate.length = 7;

      this.reportDate.length > 7
        ? (this.reportDate.length = 7)
        : (this.reportDate.length = this.reportDate.length);

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },
  },
});
</script>
