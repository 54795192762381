<template>
  <div :class="widgetClasses" class="card">
    <div class="card-body p-0" style="height: 248px" v-if="!isShow"></div>
    <div class="card-body p-0" v-if="isShow">
      <div style="padding: 24px 27px 0px 27px">
        <div class="me-2 flex-grow-1">
          <div class="fs-3 mb-2">
            <span style="color: grey">฿ </span>
            <span class="fw-boldest"
              >{{ formatThousand(sumPdPrice) }}{{ " " }}
            </span>

            <button
              style="padding: 5px"
              :class="
                compareValue === 0
                  ? 'btn btn-sm btn-light-primary fw-boldest'
                  : compareValue > 0
                  ? 'btn btn-sm btn-light-success fw-boldest'
                  : 'btn btn-sm btn-light-danger fw-boldest'
              "
              data-toggle="tooltip"
              :title="`Last year : ${formatThousand(sumPdPriceLastYear)}`"
            >
              <i
                :class="
                  compareValue === 0
                    ? 'bi bi-arrow-right'
                    : compareValue > 0
                    ? 'bi bi-arrow-up'
                    : 'bi bi-arrow-down'
                "
              ></i
              >{{ compareValue.toFixed(1) }} %
            </button>
          </div>

          <div class="fw-bold fs-6 text-gray-600">มูลค่าคลังสินค้าปัจจุบัน</div>
        </div>
      </div>

      <div>
        <div class="mt-3 pb-4">
          <div class="row pb-4">
            <div class="col-12 text-center text-gray-600 fw-boldest">Min</div>
          </div>

          <div class="row">
            <div class="col-2"></div>
            <div class="col-8">
              <apexchart
                type="radialBar"
                height="220"
                :options="chartOptions"
                :series="series"
              ></apexchart>
            </div>
            <div class="col-2"></div>
          </div>

          <div class="row" style="margin-top: -15px">
            <div class="col-4 text-end text-gray-600 fw-boldest">Low</div>
            <div class="col-4"></div>
            <div class="col-4 text-gray-600 fw-boldest">Max</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";

import whApi from "@/api/warehouse/";
import reportApi from "@/api/report/";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

export default defineComponent({
  name: "kt-widget-1",
  props: {
    widgetClasses: String,
    color: String,

    categories: Array,

    searchDataByYear: Array,
    searchDataByYearLastYear: Array,
  },
  components: {},
  setup(props) {
    const color = ref(props.color);

    const series = [];

    const chartOptions = {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      colors: ["#E91E63"],
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "#DCDCDC",
            strokeWidth: "97%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#7337EE",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          shadeIntensity: 0.4,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ["Average Results"],
      stroke: {
        lineCap: "round",
      },
    };

    return {
      chartOptions,
      series,
    };
  },

  data: () => ({
    isShow: false,

    searchInput: [],
    searchInputLastYear: [],

    pdItems: [],

    avgMinMax: 0,
    sumPdAmount: 0,
    sumPdPrice: 0,
    sumPdMin: 0,
    sumPdMax: 0,

    avgMinMaxLastYear: 0,
    sumPdAmountLastYear: 0,
    sumPdPriceLastYear: 0,
    sumPdMinLastYear: 0,
    sumPdMaxLastYear: 0,

    compareValue: 0,
  }),

  async created() {
    await this.search();
    await this.searchLastYear();
  },

  methods: {
    formatThousand(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
        });
        return newVal;
      }
    },

    // current year
    async search() {
      this.sumAllAmount(this.searchDataByYear);
    },

    sumAllAmount(item) {
      this.sumPdAmount = item.reduce(
        (sum, data) => sum + data.product_bring_qty,
        0
      );

      this.sumPdPrice = item.reduce(
        (sum, data) =>
          sum +
          parseFloat(data.product_bring_qty) *
            parseFloat(data.product_retail_price),
        0
      );

      this.sumPdMin = item.reduce(
        (sum, data) => sum + data.product_min_stock,
        0
      );

      this.sumPdMax = item.reduce(
        (sum, data) => sum + data.product_max_stock,
        0
      );

      this.calculateAllAmount();
    },

    calculateAllAmount() {
      this.avgMinMax =
        (parseFloat(this.sumPdAmount) * 100) /
        parseFloat(this.sumPdMax === 0 ? 1 : this.sumPdMax);

      this.avgMinMax = this.avgMinMax.toFixed(2);

      this.series.push(this.avgMinMax);
    },

    // last year
    async searchLastYear() {
      this.sumAllAmount1(this.searchDataByYearLastYear);
    },

    sumAllAmount1(item) {
      this.sumPdAmountLastYear = item.reduce(
        (sum, data) => sum + data.product_bring_qty,
        0
      );

      this.sumPdPriceLastYear = item.reduce(
        (sum, data) =>
          sum +
          parseFloat(data.product_bring_qty) *
            parseFloat(data.product_retail_price),
        0
      );

      this.sumPdMinLastYear = item.reduce(
        (sum, data) => sum + data.product_min_stock,
        0
      );

      this.sumPdMaxLastYear = item.reduce(
        (sum, data) => sum + data.product_max_stock,
        0
      );

      this.compereLastYear();
    },

    compereLastYear() {
      if (this.sumPdPriceLastYear === 0) {
        this.compareValue = 100;
      } else {
        this.compareValue =
          ((parseFloat(this.sumPdPrice) - parseFloat(this.sumPdPriceLastYear)) /
            parseFloat(this.sumPdPriceLastYear)) *
          100;
      }

      setTimeout(() => {
        this.isShow = true;
      }, 500);
    },
  },
});
</script>
