<template>
  <!--  ROW 1  -->
  <div v-if="!stateLoading">
    <div class="row g-xl-8">
      <div class="col-xxl-6">
        <div class="row g-xl-8">
          <div class="col-xl-6 mb-5 mb-xl-8" v-if="loadingByDay">
            <Loader1 />
          </div>
          <div class="col-xl-6" v-if="!loadingByDay">
            <KTEngageWidget3
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :searchByDay="searchByDay"
              :searchByDayLastYear="searchByDayLastYear"
            ></KTEngageWidget3>
          </div>

          <div class="col-xl-6 mb-5 mb-xl-8" v-if="loadingByDay">
            <Loader1 />
          </div>
          <div class="col-xl-6" v-if="!loadingByDay">
            <KTEngageWidget4
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :categories="categories"
              :searchByDay="searchByDay"
              :searchByDayLastYear="searchByDayLastYear"
            ></KTEngageWidget4>
          </div>
        </div>

        <div class="row g-xl-8">
          <div class="col-xl-6" v-if="loadingByYear">
            <Loader2 />
          </div>
          <div class="col-xl-6" v-if="!loadingByYear">
            <KTEngageWidget5
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :categories="categories"
              :searchDataByYear="searchDataByYear"
              :searchDataByYearLastYear="searchDataByYearLastYear"
            ></KTEngageWidget5>
          </div>

          <div class="col-xl-6" v-if="loadingByHightLight">
            <Loader2 />
          </div>
          <div class="col-xl-6" v-if="!loadingByHightLight">
            <KTEngageWidget6
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :categories="categories"
              :searchHightLights="searchHightLights"
              :searchHightLightsLastYear="searchHightLightsLastYear"
              :searchOutOfStock="searchOutOfStock"
              :searchOutOfStockLastYear="searchOutOfStockLastYear"
            ></KTEngageWidget6>
          </div>
        </div>
      </div>

      <div class="col-xxl-6">
        <div class="row g-xl-8">
          <div class="col mb-8" v-if="loadingBySalesThisYear">
            <Loader3 />
          </div>
          <div class="col" v-if="!loadingBySalesThisYear">
            <KTChartWidget1
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :inputDateSubtractTen="inputDateSubtractTen"
              :inputDateLastYearSubtractTen="inputDateLastYearSubtractTen"
              :searchTenMonth="searchTenMonth"
              :searchTenMonthLastYear="searchTenMonthLastYear"
              :searchSalesThisYear="searchSalesThisYear"
              :searchSalesThisYearLastYear="searchSalesThisYearLastYear"
            ></KTChartWidget1>
          </div>
        </div>
      </div>
    </div>

    <!--  ROW 2  -->
    <div class="row g-xl-8">
      <div class="col-xxl-6">
        <div class="row g-xl-8">
          <div class="col" v-if="loadingBySalesThisMonth">
            <Loader4 />
          </div>
          <div class="col" v-if="!loadingBySalesThisMonth">
            <KTChartWidget2
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :categories="categories"
              :searchSalesThisMonth="searchSalesThisMonth"
              :searchSalesThisMonthLastYear="searchSalesThisMonthLastYear"
            ></KTChartWidget2>
          </div>
        </div>
      </div>

      <div class="col-xxl-6">
        <div class="row g-xl-8">
          <div class="col mb-8" v-if="loadingSnapshotTen">
            <Loader5 />
          </div>
          <div class="col" v-if="!loadingSnapshotTen">
            <KTChartWidget3
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :inputDate="inputDate"
              :snapshotTen="snapshotTen"
              :snapshotTenLastYear="snapshotTenLastYear"
              :snapshotTenForSum="snapshotTenForSum"
              :snapshotTenLastYearForSum="snapshotTenLastYearForSum"
            ></KTChartWidget3>
          </div>
        </div>
      </div>
    </div>

    <!--  ROW 3  -->
    <div class="row g-xl-8">
      <div class="col-xxl-4">
        <div class="row g-xl-8">
          <div class="col mb-8" v-if="loadingTrans">
            <Loader5 />
          </div>
          <div class="col" v-if="!loadingTrans">
            <KTTablesWidget2
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :searchTrans="searchTrans"
              :searchTransLastYear="searchTransLastYear"
            ></KTTablesWidget2>
          </div>
        </div>
      </div>

      <div class="col-xxl-8">
        <div class="row g-xl-8">
          <div class="col" v-if="loadingBestSeller">
            <Loader6 />
          </div>
          <div class="col" v-if="!loadingBestSeller">
            <KTTablesWidget3
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :pdItems="pdItems"
              :searchBestSeller="searchBestSeller"
              :categories="categories"
            ></KTTablesWidget3>
          </div>
        </div>
      </div>
    </div>

    <!--  ROW 4  -->
    <div class="row g-xl-8">
      <div class="col-xxl-4">
        <div class="row g-xl-8">
          <div class="col">
            <KTEngageWidget2
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
            ></KTEngageWidget2>
          </div>
        </div>
      </div>

      <div class="col-xxl-8">
        <div class="row g-xl-8">
          <div class="col" v-if="loadingStockReport">
            <Loader6 />
          </div>
          <div class="col" v-if="!loadingStockReport">
            <KTTablesWidget4
              widget-classes="card-xl-stretch mb-5 mb-xl-8"
              :stockReport="stockReport"
              :categories="categories"
            ></KTTablesWidget4>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--begin::Modals-->
  <!-- <KTCreateAccountModal></KTCreateAccountModal>
  <KTNewCardModal></KTNewCardModal> -->
  <!--end::Modals-->
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTChartWidget2 from "@/components/widgets/charts/Widget2.vue";
import KTChartWidget3 from "@/components/widgets/charts/Widget3.vue";
import KTEngageWidget1 from "@/components/widgets/engage/Widget1.vue";
import KTEngageWidget2 from "@/components/widgets/engage/Widget2.vue";
import KTEngageWidget3 from "@/components/widgets/engage/Widget3.vue";
import KTEngageWidget4 from "@/components/widgets/engage/Widget4.vue";
import KTEngageWidget5 from "@/components/widgets/engage/Widget5.vue";
import KTEngageWidget6 from "@/components/widgets/engage/Widget6.vue";
import KTListWidget1 from "@/components/widgets/lists/Widget1.vue";
import KTListWidget2 from "@/components/widgets/lists/Widget2.vue";
import KTListWidget3 from "@/components/widgets/lists/Widget3.vue";
import KTListWidget4 from "@/components/widgets/lists/Widget4.vue";
import KTSliderWidget1 from "@/components/widgets/sliders/Widget1.vue";
import KTTablesWidget1 from "@/components/widgets/tables/Widget1.vue";
import KTTablesWidget2 from "@/components/widgets/tables/Widget2.vue";
import KTTablesWidget3 from "@/components/widgets/tables/Widget3.vue";
import KTTablesWidget4 from "@/components/widgets/tables/Widget4.vue";
import KTNewCardModal from "@/components/modals/NewCardModal.vue";
import KTCreateAccountModal from "@/components/modals/CreateAccountModal.vue";

import Loader1 from "./components/loaderDashboard/chart1.vue";
import Loader2 from "./components/loaderDashboard/chart2.vue";
import Loader3 from "./components/loaderDashboard/chart3.vue";
import Loader4 from "./components/loaderDashboard/chart4.vue";
import Loader5 from "./components/loaderDashboard/chart5.vue";
import Loader6 from "./components/loaderDashboard/chart6.vue";

import whApi from "@/api/warehouse/";
import reportApi from "@/api/report/";

import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import numbro from "numbro";

import { Mutations } from "@/store/enums/StoreEnums";

import { Decode, Encode } from "@/services";

import Swal from "sweetalert2/dist/sweetalert2.js";

import { calStep1 } from "@/utils/calDashboard/calStep1";
import { calStep2, calStep2BySales } from "@/utils/calDashboard/calStep2";
import { calStep3, calStepOutOfStock } from "@/utils/calDashboard/calStep3";
import { calStep4 } from "@/utils/calDashboard/calStep4";
import { calStep5 } from "@/utils/calDashboard/calStep5";
import { calStep6 } from "@/utils/calDashboard/calStep6";

import loaderOverlay from "./components/loaderDashboard/preLoader.vue";

import { mapState } from "vuex";

export default defineComponent({
  name: "dashboard",
  components: {
    KTChartWidget1,
    KTChartWidget2,
    KTChartWidget3,
    KTEngageWidget1,
    KTEngageWidget2,
    KTEngageWidget3,
    KTEngageWidget4,
    KTEngageWidget5,
    KTEngageWidget6,
    KTListWidget1,
    KTListWidget2,
    KTListWidget3,
    KTListWidget4,
    KTSliderWidget1,
    KTTablesWidget1,
    KTTablesWidget2,
    KTTablesWidget3,
    KTTablesWidget4,
    KTNewCardModal,
    KTCreateAccountModal,

    Loader1,
    Loader2,
    Loader3,
    Loader4,
    Loader5,
    Loader6,

    loaderOverlay,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Dashboard", ["Dashboards"]);
    });
    document.title = "BELUCA | Dashboard";
  },

  data: () => ({
    loadingOverlay: false,

    loading: false,
    loadingByDay: true,
    loadingByYear: true,
    loadingByHightLight: true,
    loadingBySalesThisYear: true,
    loadingBySalesThisMonth: true,
    loadingTrans: true,
    loadingBestSeller: true,
    loadingSnapshotTen: true,
    loadingStockReport: true,

    inputDate: [],
    inputDateLastYear: [],

    inputDateSubtract: [],
    inputDateLastYearSubtract: [],

    inputDateSubtractTen: [],
    inputDateLastYearSubtractTen: [],

    inputDateSubtractMonth: [],
    inputDateLastYearSubtractMonth: [],

    categories: [],
    pdItems: [],

    // chart 1, 2
    searchByDay: [],
    searchByDayLastYear: [],

    // chart 3
    searchDataByYear: [],
    searchDataByYearLastYear: [],

    // chart 4
    searchHightLights: [],
    searchHightLightsLastYear: [],

    searchOutOfStock: [],
    searchOutOfStockLastYear: [],

    // chart 5
    searchTenMonth: [],
    searchTenMonthLastYear: [],

    searchSalesThisYear: [],
    searchSalesThisYearLastYear: [],

    // chart 6
    searchSalesThisMonth: [],
    searchSalesThisMonthLastYear: [],

    // chart 7
    snapshotTen: [],
    snapshotTenLastYear: [],
    snapshotTenForSum: [],
    snapshotTenLastYearForSum: [],

    // chart 8
    searchTrans: [],
    searchTransLastYear: [],

    // chart 9
    searchBestSeller: [],

    // last chart
    stockReport: [],
  }),

  computed: {
    ...mapState({
      stateLoading: (state) => state.MockItems.isLoading,
    }),
  },

  async created() {
    this.checkQuery();

    this.loadingOverlay = true;
    this.$store.commit(Mutations.SET_LOADING, true);

    this.setDate();

    this.getMainPd();
    this.getPd();

    // chart 1, 2
    this.searchByDates();

    // // chart 3
    this.searchByYear();

    // chart 4
    this.searchByHightLights();

    // chart 5
    this.searchBySalesThisYear();

    // chart 6
    this.searchSalesByMonth();

    // chart 7
    this.searchSnapshotTen();

    // chart 8
    this.searchTranSummary();

    // chart 9
    this.searchBestSellerSummary();

    // last chart
    this.searchStockReport();

    setTimeout(() => {
      this.$store.commit(Mutations.SET_LOADING, false);
    }, 500);
  },

  methods: {
    async checkQuery() {
      if (this.$route.query.login) {
        await this.$router.push({ query: {} });
        this.$router.go(0);
      }
    },
    setDate() {
      // chart 1, 2, 8
      this.inputDate[0] = dayjs(new Date());
      this.inputDate[1] = dayjs(new Date()).add(1, "day");
      this.inputDateLastYear[0] = dayjs(new Date()).subtract(12, "month");
      this.inputDateLastYear[1] = dayjs(new Date())
        .subtract(12, "month")
        .add(1, "day");

      // chart 3
      this.inputDateSubtract[0] = dayjs(new Date()).subtract(12, "month");
      this.inputDateSubtract[1] = dayjs(new Date()).add(1, "day");
      this.inputDateLastYearSubtract[0] = dayjs(new Date()).subtract(
        24,
        "month"
      );
      this.inputDateLastYearSubtract[1] = dayjs(new Date())
        .subtract(12, "month")
        .add(1, "day");

      // chart 5
      this.inputDateSubtractTen[0] = dayjs(new Date()).subtract(10, "month");
      this.inputDateSubtractTen[1] = dayjs(new Date()).add(1, "day");

      this.inputDateLastYearSubtractTen[0] = dayjs(new Date()).subtract(
        20,
        "month"
      );
      this.inputDateLastYearSubtractTen[1] = dayjs(new Date())
        .subtract(10, "month")
        .add(1, "day");

      // chart 6
      this.inputDateSubtractMonth[0] = dayjs().startOf("month");
      this.inputDateSubtractMonth[1] = dayjs().endOf("month").add(1, "day");

      this.inputDateLastYearSubtractMonth[0] = dayjs(
        this.inputDateSubtractMonth[0]
      ).subtract(12, "month");
      this.inputDateLastYearSubtractMonth[1] = dayjs(
        this.inputDateSubtractMonth[1]
      )
        .subtract(12, "month")
        .add(1, "day");
    },

    async getMainPd() {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.mainProduct.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.categories = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    async getPd() {
      this.loading = true;
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.product.getAll({
          is_cancel: "0",
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }

      if (responseData.response_status === "SUCCESS") {
        this.pdItems = responseData.data;
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER.",
        });
      }
    },

    // chart 1,2
    async searchByDates() {
      let returnValue = [];
      let returnValue2 = [];

      // current year
      returnValue = await calStep1(this.inputDate);

      // last year
      returnValue2 = await calStep1(this.inputDateLastYear);

      if (
        returnValue.response_status === "SUCCESS" &&
        returnValue2.response_status === "SUCCESS"
      ) {
        this.searchByDay = returnValue.data;
        this.searchByDayLastYear = returnValue2.data;
        setTimeout(() => {
          this.loadingByDay = false;
        }, 500);
      } else {
        this.loadingByDay = false;
      }
    },
    // chart 1,2

    // chart 3
    async searchByYear() {
      let returnValue = [];
      let returnValue2 = [];

      // current year
      returnValue = await calStep2(this.inputDateSubtract);

      // last year
      returnValue2 = await calStep2(this.inputDateLastYearSubtract);

      if (
        returnValue.response_status === "SUCCESS" &&
        returnValue2.response_status === "SUCCESS"
      ) {
        this.searchDataByYear = returnValue.data;
        this.searchDataByYearLastYear = returnValue2.data;
        setTimeout(() => {
          this.loadingByYear = false;
        }, 500);
      } else {
        this.loadingByYear = false;
      }
    },
    // chart 3

    // chart 4
    async searchByHightLights() {
      let returnValue = [];
      let returnValue2 = [];

      let returnLastYearValue = [];
      let returnLastYearValue2 = [];

      // current year
      returnValue = await calStep3(this.inputDate);
      returnValue2 = await calStepOutOfStock(this.inputDate);

      // last year
      returnLastYearValue = await calStep3(this.inputDateLastYear);
      returnLastYearValue2 = await calStepOutOfStock(this.inputDateLastYear);

      if (
        returnValue.response_status === "SUCCESS" &&
        returnValue2.response_status === "SUCCESS" &&
        returnLastYearValue.response_status === "SUCCESS" &&
        returnLastYearValue2.response_status === "SUCCESS"
      ) {
        this.searchHightLights = returnValue.data;
        this.searchHightLightsLastYear = returnLastYearValue.data;

        this.searchOutOfStock = returnValue2.data;
        this.searchOutOfStockLastYear = returnLastYearValue2.data;
        setTimeout(() => {
          this.loadingByHightLight = false;
        }, 500);
      } else {
        this.loadingByHightLight = false;
      }
    },
    // chart 4

    // chart 5
    async searchBySalesThisYear() {
      let returnValue = [];
      let returnValueSales = [];

      let returnValue2 = [];
      let returnValueSales2 = [];

      // current year
      returnValue = await calStep2(this.inputDateSubtractTen);
      returnValueSales = await calStep2BySales(this.inputDateSubtractTen);

      // last year
      returnValue2 = await calStep3(this.inputDateLastYearSubtractTen);
      returnValueSales2 = await calStepOutOfStock(
        this.inputDateLastYearSubtractTen
      );

      if (
        returnValue.response_status === "SUCCESS" &&
        returnValueSales.response_status === "SUCCESS" &&
        returnValue2.response_status === "SUCCESS" &&
        returnValueSales2.response_status === "SUCCESS"
      ) {
        this.searchTenMonth = returnValue.data;
        this.searchSalesThisYear = returnValueSales.data;

        this.searchTenMonthLastYear = returnValue2.data;
        this.searchSalesThisYearLastYear = returnValueSales2.data;
        setTimeout(() => {
          this.loadingBySalesThisYear = false;
        }, 500);
      } else {
        this.loadingBySalesThisYear = false;
      }
    },
    // chart 5

    // chart 6
    async searchSalesByMonth() {
      let returnValue = [];
      let returnValue2 = [];

      // current year
      returnValue = await calStep1(this.inputDateSubtractMonth);

      // // last year
      returnValue2 = await calStep1(this.inputDateLastYearSubtractMonth);

      if (
        returnValue.response_status === "SUCCESS" &&
        returnValue2.response_status === "SUCCESS"
      ) {
        this.searchSalesThisMonth = returnValue.data;
        this.searchSalesThisMonthLastYear = returnValue2.data;
        setTimeout(() => {
          this.loadingBySalesThisMonth = false;
        }, 500);
      } else {
        this.loadingBySalesThisMonth = false;
      }
    },
    // chart 6

    // chart 7
    async searchSnapshotTen() {
      let returnValue = [];
      let returnValue2 = [];
      let returnValue3 = [];
      let returnValue4 = [];

      // current year
      returnValue = await calStep6(this.inputDate[0]);

      // // last year
      returnValue2 = await calStep6(this.inputDateLastYear[0]);

      // current year
      returnValue3 = await calStep6(this.inputDate[0]);

      // // last year
      returnValue4 = await calStep6(this.inputDateLastYear[0]);

      if (
        returnValue.response_status === "SUCCESS" &&
        returnValue2.response_status === "SUCCESS"
      ) {
        this.snapshotTen = returnValue.data;
        this.snapshotTenLastYear = returnValue2.data;

        this.snapshotTenForSum = returnValue3.data;
        this.snapshotTenLastYearForSum = returnValue4.data;

        setTimeout(() => {
          this.loadingSnapshotTen = false;
        }, 500);
      } else {
        this.loadingSnapshotTen = false;
      }
    },
    // chart 7

    // chart 8, 9
    async searchTranSummary() {
      let returnValue = [];
      let returnValue2 = [];

      // current year
      returnValue = await calStep4(this.inputDate);

      // // last year
      returnValue2 = await calStep4(this.inputDateLastYear);

      if (
        returnValue.response_status === "SUCCESS" &&
        returnValue2.response_status === "SUCCESS"
      ) {
        this.searchTrans = returnValue.data;
        this.searchTransLastYear = returnValue2.data;
        setTimeout(() => {
          this.loadingTrans = false;
        }, 500);
      } else {
        this.loadingTrans = false;
      }
    },
    // chart 8, 9

    // chart 9
    async searchBestSellerSummary() {
      let returnValue = [];

      // current year
      returnValue = await calStep1(this.inputDateSubtractMonth);

      if (returnValue.response_status === "SUCCESS") {
        this.searchBestSeller = returnValue.data;
        setTimeout(() => {
          this.loadingBestSeller = false;
        }, 500);
      } else {
        this.loadingBestSeller = false;
      }
    },
    // chart 9

    // last chart

    async searchStockReport() {
      let returnValue = [];

      let newDate = [];
      newDate[0] = dayjs(new Date()).subtract(1, "day");
      newDate[1] = dayjs(new Date());

      returnValue = await calStep5(newDate);

      if (returnValue.response_status === "SUCCESS") {
        this.stockReport = returnValue.data;
        setTimeout(() => {
          this.loadingStockReport = false;
        }, 500);
      } else {
        this.loadingStockReport = false;
      }
    },

    // last chart
  },
});
</script>
